function D(l) {
  return l && l.__esModule && Object.prototype.hasOwnProperty.call(l, "default") ? l.default : l;
}
var k = {}, v = {};
(function(l) {
  Object.defineProperty(l, "__esModule", {
    value: !0
  }), Object.defineProperty(l, "default", {
    enumerable: !0,
    get: function() {
      return r;
    }
  });
  function p(e, a) {
    return {
      handler: e,
      config: a
    };
  }
  p.withOptions = function(e, a = () => ({})) {
    const o = function(s) {
      return {
        __options: s,
        handler: e(s),
        config: a(s)
      };
    };
    return o.__isOptionsFunction = !0, o.__pluginFunction = e, o.__configFunction = a, o;
  };
  const r = p;
})(v);
(function(l) {
  Object.defineProperty(l, "__esModule", {
    value: !0
  }), Object.defineProperty(l, "default", {
    enumerable: !0,
    get: function() {
      return e;
    }
  });
  const p = /* @__PURE__ */ r(v);
  function r(a) {
    return a && a.__esModule ? a : {
      default: a
    };
  }
  const e = p.default;
})(k);
let t = k;
var j = (t.__esModule ? t : { default: t }).default;
const z = /* @__PURE__ */ D(j), $ = {
  gray1: "#111111",
  gray2: "#191919",
  gray3: "#222222",
  gray4: "#2a2a2a",
  gray5: "#313131",
  gray6: "#3a3a3a",
  gray7: "#484848",
  gray8: "#606060",
  gray9: "#6e6e6e",
  gray10: "#7b7b7b",
  gray11: "#b4b4b4",
  gray12: "#eeeeee"
}, h = {
  grayA1: "#00000000",
  grayA2: "#ffffff09",
  grayA3: "#ffffff12",
  grayA4: "#ffffff1b",
  grayA5: "#ffffff22",
  grayA6: "#ffffff2c",
  grayA7: "#ffffff3b",
  grayA8: "#ffffff55",
  grayA9: "#ffffff64",
  grayA10: "#ffffff72",
  grayA11: "#ffffffaf",
  grayA12: "#ffffffed"
}, C = {
  gray1: "color(display-p3 0.067 0.067 0.067)",
  gray2: "color(display-p3 0.098 0.098 0.098)",
  gray3: "color(display-p3 0.135 0.135 0.135)",
  gray4: "color(display-p3 0.163 0.163 0.163)",
  gray5: "color(display-p3 0.192 0.192 0.192)",
  gray6: "color(display-p3 0.228 0.228 0.228)",
  gray7: "color(display-p3 0.283 0.283 0.283)",
  gray8: "color(display-p3 0.375 0.375 0.375)",
  gray9: "color(display-p3 0.431 0.431 0.431)",
  gray10: "color(display-p3 0.484 0.484 0.484)",
  gray11: "color(display-p3 0.706 0.706 0.706)",
  gray12: "color(display-p3 0.933 0.933 0.933)"
}, _ = {
  grayA1: "color(display-p3 0 0 0 / 0)",
  grayA2: "color(display-p3 1 1 1 / 0.034)",
  grayA3: "color(display-p3 1 1 1 / 0.071)",
  grayA4: "color(display-p3 1 1 1 / 0.105)",
  grayA5: "color(display-p3 1 1 1 / 0.134)",
  grayA6: "color(display-p3 1 1 1 / 0.172)",
  grayA7: "color(display-p3 1 1 1 / 0.231)",
  grayA8: "color(display-p3 1 1 1 / 0.332)",
  grayA9: "color(display-p3 1 1 1 / 0.391)",
  grayA10: "color(display-p3 1 1 1 / 0.445)",
  grayA11: "color(display-p3 1 1 1 / 0.685)",
  grayA12: "color(display-p3 1 1 1 / 0.929)"
}, N = {
  mauve1: "#121113",
  mauve2: "#1a191b",
  mauve3: "#232225",
  mauve4: "#2b292d",
  mauve5: "#323035",
  mauve6: "#3c393f",
  mauve7: "#49474e",
  mauve8: "#625f69",
  mauve9: "#6f6d78",
  mauve10: "#7c7a85",
  mauve11: "#b5b2bc",
  mauve12: "#eeeef0"
}, O = {
  mauveA1: "#00000000",
  mauveA2: "#f5f4f609",
  mauveA3: "#ebeaf814",
  mauveA4: "#eee5f81d",
  mauveA5: "#efe6fe25",
  mauveA6: "#f1e6fd30",
  mauveA7: "#eee9ff40",
  mauveA8: "#eee7ff5d",
  mauveA9: "#eae6fd6e",
  mauveA10: "#ece9fd7c",
  mauveA11: "#f5f1ffb7",
  mauveA12: "#fdfdffef"
}, x = {
  mauve1: "color(display-p3 0.07 0.067 0.074)",
  mauve2: "color(display-p3 0.101 0.098 0.105)",
  mauve3: "color(display-p3 0.138 0.134 0.144)",
  mauve4: "color(display-p3 0.167 0.161 0.175)",
  mauve5: "color(display-p3 0.196 0.189 0.206)",
  mauve6: "color(display-p3 0.232 0.225 0.245)",
  mauve7: "color(display-p3 0.286 0.277 0.302)",
  mauve8: "color(display-p3 0.383 0.373 0.408)",
  mauve9: "color(display-p3 0.434 0.428 0.467)",
  mauve10: "color(display-p3 0.487 0.48 0.519)",
  mauve11: "color(display-p3 0.707 0.7 0.735)",
  mauve12: "color(display-p3 0.933 0.933 0.94)"
}, S = {
  mauveA1: "color(display-p3 0 0 0 / 0)",
  mauveA2: "color(display-p3 0.996 0.992 1 / 0.034)",
  mauveA3: "color(display-p3 0.937 0.933 0.992 / 0.077)",
  mauveA4: "color(display-p3 0.957 0.918 0.996 / 0.111)",
  mauveA5: "color(display-p3 0.937 0.906 0.996 / 0.145)",
  mauveA6: "color(display-p3 0.953 0.925 0.996 / 0.183)",
  mauveA7: "color(display-p3 0.945 0.929 1 / 0.246)",
  mauveA8: "color(display-p3 0.937 0.918 1 / 0.361)",
  mauveA9: "color(display-p3 0.933 0.918 1 / 0.424)",
  mauveA10: "color(display-p3 0.941 0.925 1 / 0.479)",
  mauveA11: "color(display-p3 0.965 0.961 1 / 0.712)",
  mauveA12: "color(display-p3 0.992 0.992 1 / 0.937)"
}, M = {
  slate1: "#111113",
  slate2: "#18191b",
  slate3: "#212225",
  slate4: "#272a2d",
  slate5: "#2e3135",
  slate6: "#363a3f",
  slate7: "#43484e",
  slate8: "#5a6169",
  slate9: "#696e77",
  slate10: "#777b84",
  slate11: "#b0b4ba",
  slate12: "#edeef0"
}, F = {
  slateA1: "#00000000",
  slateA2: "#d8f4f609",
  slateA3: "#ddeaf814",
  slateA4: "#d3edf81d",
  slateA5: "#d9edfe25",
  slateA6: "#d6ebfd30",
  slateA7: "#d9edff40",
  slateA8: "#d9edff5d",
  slateA9: "#dfebfd6d",
  slateA10: "#e5edfd7b",
  slateA11: "#f1f7feb5",
  slateA12: "#fcfdffef"
}, I = {
  slate1: "color(display-p3 0.067 0.067 0.074)",
  slate2: "color(display-p3 0.095 0.098 0.105)",
  slate3: "color(display-p3 0.13 0.135 0.145)",
  slate4: "color(display-p3 0.156 0.163 0.176)",
  slate5: "color(display-p3 0.183 0.191 0.206)",
  slate6: "color(display-p3 0.215 0.226 0.244)",
  slate7: "color(display-p3 0.265 0.28 0.302)",
  slate8: "color(display-p3 0.357 0.381 0.409)",
  slate9: "color(display-p3 0.415 0.431 0.463)",
  slate10: "color(display-p3 0.469 0.483 0.514)",
  slate11: "color(display-p3 0.692 0.704 0.728)",
  slate12: "color(display-p3 0.93 0.933 0.94)"
}, E = {
  slateA1: "color(display-p3 0 0 0 / 0)",
  slateA2: "color(display-p3 0.875 0.992 1 / 0.034)",
  slateA3: "color(display-p3 0.882 0.933 0.992 / 0.077)",
  slateA4: "color(display-p3 0.882 0.953 0.996 / 0.111)",
  slateA5: "color(display-p3 0.878 0.929 0.996 / 0.145)",
  slateA6: "color(display-p3 0.882 0.949 0.996 / 0.183)",
  slateA7: "color(display-p3 0.882 0.929 1 / 0.246)",
  slateA8: "color(display-p3 0.871 0.937 1 / 0.361)",
  slateA9: "color(display-p3 0.898 0.937 1 / 0.42)",
  slateA10: "color(display-p3 0.918 0.945 1 / 0.475)",
  slateA11: "color(display-p3 0.949 0.969 0.996 / 0.708)",
  slateA12: "color(display-p3 0.988 0.992 1 / 0.937)"
}, L = {
  sage1: "#101211",
  sage2: "#171918",
  sage3: "#202221",
  sage4: "#272a29",
  sage5: "#2e3130",
  sage6: "#373b39",
  sage7: "#444947",
  sage8: "#5b625f",
  sage9: "#63706b",
  sage10: "#717d79",
  sage11: "#adb5b2",
  sage12: "#eceeed"
}, q = {
  sageA1: "#00000000",
  sageA2: "#f0f2f108",
  sageA3: "#f3f5f412",
  sageA4: "#f2fefd1a",
  sageA5: "#f1fbfa22",
  sageA6: "#edfbf42d",
  sageA7: "#edfcf73c",
  sageA8: "#ebfdf657",
  sageA9: "#dffdf266",
  sageA10: "#e5fdf674",
  sageA11: "#f4fefbb0",
  sageA12: "#fdfffeed"
}, T = {
  sage1: "color(display-p3 0.064 0.07 0.067)",
  sage2: "color(display-p3 0.092 0.098 0.094)",
  sage3: "color(display-p3 0.128 0.135 0.131)",
  sage4: "color(display-p3 0.155 0.164 0.159)",
  sage5: "color(display-p3 0.183 0.193 0.188)",
  sage6: "color(display-p3 0.218 0.23 0.224)",
  sage7: "color(display-p3 0.269 0.285 0.277)",
  sage8: "color(display-p3 0.362 0.382 0.373)",
  sage9: "color(display-p3 0.398 0.438 0.421)",
  sage10: "color(display-p3 0.453 0.49 0.474)",
  sage11: "color(display-p3 0.685 0.709 0.697)",
  sage12: "color(display-p3 0.927 0.933 0.93)"
}, V = {
  sageA1: "color(display-p3 0 0 0 / 0)",
  sageA2: "color(display-p3 0.976 0.988 0.984 / 0.03)",
  sageA3: "color(display-p3 0.992 0.945 0.941 / 0.072)",
  sageA4: "color(display-p3 0.988 0.996 0.992 / 0.102)",
  sageA5: "color(display-p3 0.992 1 0.996 / 0.131)",
  sageA6: "color(display-p3 0.973 1 0.976 / 0.173)",
  sageA7: "color(display-p3 0.957 1 0.976 / 0.233)",
  sageA8: "color(display-p3 0.957 1 0.984 / 0.334)",
  sageA9: "color(display-p3 0.902 1 0.957 / 0.397)",
  sageA10: "color(display-p3 0.929 1 0.973 / 0.452)",
  sageA11: "color(display-p3 0.969 1 0.988 / 0.688)",
  sageA12: "color(display-p3 0.992 1 0.996 / 0.929)"
}, B = {
  olive1: "#111210",
  olive2: "#181917",
  olive3: "#212220",
  olive4: "#282a27",
  olive5: "#2f312e",
  olive6: "#383a36",
  olive7: "#454843",
  olive8: "#5c625b",
  olive9: "#687066",
  olive10: "#767d74",
  olive11: "#afb5ad",
  olive12: "#eceeec"
}, H = {
  oliveA1: "#00000000",
  oliveA2: "#f1f2f008",
  oliveA3: "#f4f5f312",
  oliveA4: "#f3fef21a",
  oliveA5: "#f2fbf122",
  oliveA6: "#f4faed2c",
  oliveA7: "#f2fced3b",
  oliveA8: "#edfdeb57",
  oliveA9: "#ebfde766",
  oliveA10: "#f0fdec74",
  oliveA11: "#f6fef4b0",
  oliveA12: "#fdfffded"
}, J = {
  olive1: "color(display-p3 0.067 0.07 0.063)",
  olive2: "color(display-p3 0.095 0.098 0.091)",
  olive3: "color(display-p3 0.131 0.135 0.126)",
  olive4: "color(display-p3 0.158 0.163 0.153)",
  olive5: "color(display-p3 0.186 0.192 0.18)",
  olive6: "color(display-p3 0.221 0.229 0.215)",
  olive7: "color(display-p3 0.273 0.284 0.266)",
  olive8: "color(display-p3 0.365 0.382 0.359)",
  olive9: "color(display-p3 0.414 0.438 0.404)",
  olive10: "color(display-p3 0.467 0.49 0.458)",
  olive11: "color(display-p3 0.69 0.709 0.682)",
  olive12: "color(display-p3 0.927 0.933 0.926)"
}, K = {
  oliveA1: "color(display-p3 0 0 0 / 0)",
  oliveA2: "color(display-p3 0.984 0.988 0.976 / 0.03)",
  oliveA3: "color(display-p3 0.992 0.996 0.988 / 0.068)",
  oliveA4: "color(display-p3 0.953 0.996 0.949 / 0.102)",
  oliveA5: "color(display-p3 0.969 1 0.965 / 0.131)",
  oliveA6: "color(display-p3 0.973 1 0.969 / 0.169)",
  oliveA7: "color(display-p3 0.98 1 0.961 / 0.228)",
  oliveA8: "color(display-p3 0.961 1 0.957 / 0.334)",
  oliveA9: "color(display-p3 0.949 1 0.922 / 0.397)",
  oliveA10: "color(display-p3 0.953 1 0.941 / 0.452)",
  oliveA11: "color(display-p3 0.976 1 0.965 / 0.688)",
  oliveA12: "color(display-p3 0.992 1 0.992 / 0.929)"
}, Q = {
  sand1: "#111110",
  sand2: "#191918",
  sand3: "#222221",
  sand4: "#2a2a28",
  sand5: "#31312e",
  sand6: "#3b3a37",
  sand7: "#494844",
  sand8: "#62605b",
  sand9: "#6f6d66",
  sand10: "#7c7b74",
  sand11: "#b5b3ad",
  sand12: "#eeeeec"
}, R = {
  sandA1: "#00000000",
  sandA2: "#f4f4f309",
  sandA3: "#f6f6f513",
  sandA4: "#fefef31b",
  sandA5: "#fbfbeb23",
  sandA6: "#fffaed2d",
  sandA7: "#fffbed3c",
  sandA8: "#fff9eb57",
  sandA9: "#fffae965",
  sandA10: "#fffdee73",
  sandA11: "#fffcf4b0",
  sandA12: "#fffffded"
}, U = {
  sand1: "color(display-p3 0.067 0.067 0.063)",
  sand2: "color(display-p3 0.098 0.098 0.094)",
  sand3: "color(display-p3 0.135 0.135 0.129)",
  sand4: "color(display-p3 0.164 0.163 0.156)",
  sand5: "color(display-p3 0.193 0.192 0.183)",
  sand6: "color(display-p3 0.23 0.229 0.217)",
  sand7: "color(display-p3 0.285 0.282 0.267)",
  sand8: "color(display-p3 0.384 0.378 0.357)",
  sand9: "color(display-p3 0.434 0.428 0.403)",
  sand10: "color(display-p3 0.487 0.481 0.456)",
  sand11: "color(display-p3 0.707 0.703 0.68)",
  sand12: "color(display-p3 0.933 0.933 0.926)"
}, W = {
  sandA1: "color(display-p3 0 0 0 / 0)",
  sandA2: "color(display-p3 0.992 0.992 0.988 / 0.034)",
  sandA3: "color(display-p3 0.996 0.996 0.992 / 0.072)",
  sandA4: "color(display-p3 0.992 0.992 0.953 / 0.106)",
  sandA5: "color(display-p3 1 1 0.965 / 0.135)",
  sandA6: "color(display-p3 1 0.976 0.929 / 0.177)",
  sandA7: "color(display-p3 1 0.984 0.929 / 0.236)",
  sandA8: "color(display-p3 1 0.976 0.925 / 0.341)",
  sandA9: "color(display-p3 1 0.98 0.925 / 0.395)",
  sandA10: "color(display-p3 1 0.992 0.933 / 0.45)",
  sandA11: "color(display-p3 1 0.996 0.961 / 0.685)",
  sandA12: "color(display-p3 1 1 0.992 / 0.929)"
}, X = {
  tomato1: "#181111",
  tomato2: "#1f1513",
  tomato3: "#391714",
  tomato4: "#4e1511",
  tomato5: "#5e1c16",
  tomato6: "#6e2920",
  tomato7: "#853a2d",
  tomato8: "#ac4d39",
  tomato9: "#e54d2e",
  tomato10: "#ec6142",
  tomato11: "#ff977d",
  tomato12: "#fbd3cb"
}, Y = {
  tomatoA1: "#f1121208",
  tomatoA2: "#ff55330f",
  tomatoA3: "#ff35232b",
  tomatoA4: "#fd201142",
  tomatoA5: "#fe332153",
  tomatoA6: "#ff4f3864",
  tomatoA7: "#fd644a7d",
  tomatoA8: "#fe6d4ea7",
  tomatoA9: "#fe5431e4",
  tomatoA10: "#ff6847eb",
  tomatoA11: "#ff977d",
  tomatoA12: "#ffd6cefb"
}, Z = {
  tomato1: "color(display-p3 0.09 0.068 0.067)",
  tomato2: "color(display-p3 0.115 0.084 0.076)",
  tomato3: "color(display-p3 0.205 0.097 0.083)",
  tomato4: "color(display-p3 0.282 0.099 0.077)",
  tomato5: "color(display-p3 0.339 0.129 0.101)",
  tomato6: "color(display-p3 0.398 0.179 0.141)",
  tomato7: "color(display-p3 0.487 0.245 0.194)",
  tomato8: "color(display-p3 0.629 0.322 0.248)",
  tomato9: "color(display-p3 0.831 0.345 0.231)",
  tomato10: "color(display-p3 0.862 0.415 0.298)",
  tomato11: "color(display-p3 1 0.585 0.455)",
  tomato12: "color(display-p3 0.959 0.833 0.802)"
}, G = {
  tomatoA1: "color(display-p3 0.973 0.071 0.071 / 0.026)",
  tomatoA2: "color(display-p3 0.992 0.376 0.224 / 0.051)",
  tomatoA3: "color(display-p3 0.996 0.282 0.176 / 0.148)",
  tomatoA4: "color(display-p3 1 0.204 0.118 / 0.232)",
  tomatoA5: "color(display-p3 1 0.286 0.192 / 0.29)",
  tomatoA6: "color(display-p3 1 0.392 0.278 / 0.353)",
  tomatoA7: "color(display-p3 1 0.459 0.349 / 0.45)",
  tomatoA8: "color(display-p3 1 0.49 0.369 / 0.601)",
  tomatoA9: "color(display-p3 1 0.408 0.267 / 0.82)",
  tomatoA10: "color(display-p3 1 0.478 0.341 / 0.853)",
  tomatoA11: "color(display-p3 1 0.585 0.455)",
  tomatoA12: "color(display-p3 0.959 0.833 0.802)"
}, o0 = {
  red1: "#191111",
  red2: "#201314",
  red3: "#3b1219",
  red4: "#500f1c",
  red5: "#611623",
  red6: "#72232d",
  red7: "#8c333a",
  red8: "#b54548",
  red9: "#e5484d",
  red10: "#ec5d5e",
  red11: "#ff9592",
  red12: "#ffd1d9"
}, l0 = {
  redA1: "#f4121209",
  redA2: "#f22f3e11",
  redA3: "#ff173f2d",
  redA4: "#fe0a3b44",
  redA5: "#ff204756",
  redA6: "#ff3e5668",
  redA7: "#ff536184",
  redA8: "#ff5d61b0",
  redA9: "#fe4e54e4",
  redA10: "#ff6465eb",
  redA11: "#ff9592",
  redA12: "#ffd1d9"
}, a0 = {
  red1: "color(display-p3 0.093 0.068 0.067)",
  red2: "color(display-p3 0.118 0.077 0.079)",
  red3: "color(display-p3 0.211 0.081 0.099)",
  red4: "color(display-p3 0.287 0.079 0.113)",
  red5: "color(display-p3 0.348 0.11 0.142)",
  red6: "color(display-p3 0.414 0.16 0.183)",
  red7: "color(display-p3 0.508 0.224 0.236)",
  red8: "color(display-p3 0.659 0.298 0.297)",
  red9: "color(display-p3 0.83 0.329 0.324)",
  red10: "color(display-p3 0.861 0.403 0.387)",
  red11: "color(display-p3 1 0.57 0.55)",
  red12: "color(display-p3 0.971 0.826 0.852)"
}, p0 = {
  redA1: "color(display-p3 0.984 0.071 0.071 / 0.03)",
  redA2: "color(display-p3 0.996 0.282 0.282 / 0.055)",
  redA3: "color(display-p3 1 0.169 0.271 / 0.156)",
  redA4: "color(display-p3 1 0.118 0.267 / 0.236)",
  redA5: "color(display-p3 1 0.212 0.314 / 0.303)",
  redA6: "color(display-p3 1 0.318 0.38 / 0.374)",
  redA7: "color(display-p3 1 0.4 0.424 / 0.475)",
  redA8: "color(display-p3 1 0.431 0.431 / 0.635)",
  redA9: "color(display-p3 1 0.388 0.384 / 0.82)",
  redA10: "color(display-p3 1 0.463 0.447 / 0.853)",
  redA11: "color(display-p3 1 0.57 0.55)",
  redA12: "color(display-p3 0.971 0.826 0.852)"
}, r0 = {
  ruby1: "#191113",
  ruby2: "#1e1517",
  ruby3: "#3a141e",
  ruby4: "#4e1325",
  ruby5: "#5e1a2e",
  ruby6: "#6f2539",
  ruby7: "#883447",
  ruby8: "#b3445a",
  ruby9: "#e54666",
  ruby10: "#ec5a72",
  ruby11: "#ff949d",
  ruby12: "#fed2e1"
}, d0 = {
  rubyA1: "#f4124a09",
  rubyA2: "#fe5a7f0e",
  rubyA3: "#ff235d2c",
  rubyA4: "#fd195e42",
  rubyA5: "#fe2d6b53",
  rubyA6: "#ff447665",
  rubyA7: "#ff577d80",
  rubyA8: "#ff5c7cae",
  rubyA9: "#fe4c70e4",
  rubyA10: "#ff617beb",
  rubyA11: "#ff949d",
  rubyA12: "#ffd3e2fe"
}, e0 = {
  ruby1: "color(display-p3 0.093 0.068 0.074)",
  ruby2: "color(display-p3 0.113 0.083 0.089)",
  ruby3: "color(display-p3 0.208 0.088 0.117)",
  ruby4: "color(display-p3 0.279 0.092 0.147)",
  ruby5: "color(display-p3 0.337 0.12 0.18)",
  ruby6: "color(display-p3 0.401 0.166 0.223)",
  ruby7: "color(display-p3 0.495 0.224 0.281)",
  ruby8: "color(display-p3 0.652 0.295 0.359)",
  ruby9: "color(display-p3 0.83 0.323 0.408)",
  ruby10: "color(display-p3 0.857 0.392 0.455)",
  ruby11: "color(display-p3 1 0.57 0.59)",
  ruby12: "color(display-p3 0.968 0.83 0.88)"
}, s0 = {
  rubyA1: "color(display-p3 0.984 0.071 0.329 / 0.03)",
  rubyA2: "color(display-p3 0.992 0.376 0.529 / 0.051)",
  rubyA3: "color(display-p3 0.996 0.196 0.404 / 0.152)",
  rubyA4: "color(display-p3 1 0.173 0.416 / 0.227)",
  rubyA5: "color(display-p3 1 0.259 0.459 / 0.29)",
  rubyA6: "color(display-p3 1 0.341 0.506 / 0.358)",
  rubyA7: "color(display-p3 1 0.412 0.541 / 0.458)",
  rubyA8: "color(display-p3 1 0.431 0.537 / 0.627)",
  rubyA9: "color(display-p3 1 0.376 0.482 / 0.82)",
  rubyA10: "color(display-p3 1 0.447 0.522 / 0.849)",
  rubyA11: "color(display-p3 1 0.57 0.59)",
  rubyA12: "color(display-p3 0.968 0.83 0.88)"
}, i0 = {
  crimson1: "#191114",
  crimson2: "#201318",
  crimson3: "#381525",
  crimson4: "#4d122f",
  crimson5: "#5c1839",
  crimson6: "#6d2545",
  crimson7: "#873356",
  crimson8: "#b0436e",
  crimson9: "#e93d82",
  crimson10: "#ee518a",
  crimson11: "#ff92ad",
  crimson12: "#fdd3e8"
}, c0 = {
  crimsonA1: "#f4126709",
  crimsonA2: "#f22f7a11",
  crimsonA3: "#fe2a8b2a",
  crimsonA4: "#fd158741",
  crimsonA5: "#fd278f51",
  crimsonA6: "#fe459763",
  crimsonA7: "#fd559b7f",
  crimsonA8: "#fe5b9bab",
  crimsonA9: "#fe418de8",
  crimsonA10: "#ff5693ed",
  crimsonA11: "#ff92ad",
  crimsonA12: "#ffd5eafd"
}, y0 = {
  crimson1: "color(display-p3 0.093 0.068 0.078)",
  crimson2: "color(display-p3 0.117 0.078 0.095)",
  crimson3: "color(display-p3 0.203 0.091 0.143)",
  crimson4: "color(display-p3 0.277 0.087 0.182)",
  crimson5: "color(display-p3 0.332 0.115 0.22)",
  crimson6: "color(display-p3 0.394 0.162 0.268)",
  crimson7: "color(display-p3 0.489 0.222 0.336)",
  crimson8: "color(display-p3 0.638 0.289 0.429)",
  crimson9: "color(display-p3 0.843 0.298 0.507)",
  crimson10: "color(display-p3 0.864 0.364 0.539)",
  crimson11: "color(display-p3 1 0.56 0.66)",
  crimson12: "color(display-p3 0.966 0.834 0.906)"
}, A0 = {
  crimsonA1: "color(display-p3 0.984 0.071 0.463 / 0.03)",
  crimsonA2: "color(display-p3 0.996 0.282 0.569 / 0.055)",
  crimsonA3: "color(display-p3 0.996 0.227 0.573 / 0.148)",
  crimsonA4: "color(display-p3 1 0.157 0.569 / 0.227)",
  crimsonA5: "color(display-p3 1 0.231 0.604 / 0.286)",
  crimsonA6: "color(display-p3 1 0.337 0.643 / 0.349)",
  crimsonA7: "color(display-p3 1 0.416 0.663 / 0.454)",
  crimsonA8: "color(display-p3 0.996 0.427 0.651 / 0.614)",
  crimsonA9: "color(display-p3 1 0.345 0.596 / 0.832)",
  crimsonA10: "color(display-p3 1 0.42 0.62 / 0.853)",
  crimsonA11: "color(display-p3 1 0.56 0.66)",
  crimsonA12: "color(display-p3 0.966 0.834 0.906)"
}, f0 = {
  pink1: "#191117",
  pink2: "#21121d",
  pink3: "#37172f",
  pink4: "#4b143d",
  pink5: "#591c47",
  pink6: "#692955",
  pink7: "#833869",
  pink8: "#a84885",
  pink9: "#d6409f",
  pink10: "#de51a8",
  pink11: "#ff8dcc",
  pink12: "#fdd1ea"
}, n0 = {
  pinkA1: "#f412bc09",
  pinkA2: "#f420bb12",
  pinkA3: "#fe37cc29",
  pinkA4: "#fc1ec43f",
  pinkA5: "#fd35c24e",
  pinkA6: "#fd51c75f",
  pinkA7: "#fd62c87b",
  pinkA8: "#ff68c8a2",
  pinkA9: "#fe49bcd4",
  pinkA10: "#ff5cc0dc",
  pinkA11: "#ff8dcc",
  pinkA12: "#ffd3ecfd"
}, b0 = {
  pink1: "color(display-p3 0.093 0.068 0.089)",
  pink2: "color(display-p3 0.121 0.073 0.11)",
  pink3: "color(display-p3 0.198 0.098 0.179)",
  pink4: "color(display-p3 0.271 0.095 0.231)",
  pink5: "color(display-p3 0.32 0.127 0.273)",
  pink6: "color(display-p3 0.382 0.177 0.326)",
  pink7: "color(display-p3 0.477 0.238 0.405)",
  pink8: "color(display-p3 0.612 0.304 0.51)",
  pink9: "color(display-p3 0.775 0.297 0.61)",
  pink10: "color(display-p3 0.808 0.356 0.645)",
  pink11: "color(display-p3 1 0.535 0.78)",
  pink12: "color(display-p3 0.964 0.826 0.912)"
}, t0 = {
  pinkA1: "color(display-p3 0.984 0.071 0.855 / 0.03)",
  pinkA2: "color(display-p3 1 0.2 0.8 / 0.059)",
  pinkA3: "color(display-p3 1 0.294 0.886 / 0.139)",
  pinkA4: "color(display-p3 1 0.192 0.82 / 0.219)",
  pinkA5: "color(display-p3 1 0.282 0.827 / 0.274)",
  pinkA6: "color(display-p3 1 0.396 0.835 / 0.337)",
  pinkA7: "color(display-p3 1 0.459 0.831 / 0.442)",
  pinkA8: "color(display-p3 1 0.478 0.827 / 0.585)",
  pinkA9: "color(display-p3 1 0.373 0.784 / 0.761)",
  pinkA10: "color(display-p3 1 0.435 0.792 / 0.795)",
  pinkA11: "color(display-p3 1 0.535 0.78)",
  pinkA12: "color(display-p3 0.964 0.826 0.912)"
}, g0 = {
  plum1: "#181118",
  plum2: "#201320",
  plum3: "#351a35",
  plum4: "#451d47",
  plum5: "#512454",
  plum6: "#5e3061",
  plum7: "#734079",
  plum8: "#92549c",
  plum9: "#ab4aba",
  plum10: "#b658c4",
  plum11: "#e796f3",
  plum12: "#f4d4f4"
}, m0 = {
  plumA1: "#f112f108",
  plumA2: "#f22ff211",
  plumA3: "#fd4cfd27",
  plumA4: "#f646ff3a",
  plumA5: "#f455ff48",
  plumA6: "#f66dff56",
  plumA7: "#f07cfd70",
  plumA8: "#ee84ff95",
  plumA9: "#e961feb6",
  plumA10: "#ed70ffc0",
  plumA11: "#f19cfef3",
  plumA12: "#feddfef4"
}, u0 = {
  plum1: "color(display-p3 0.09 0.068 0.092)",
  plum2: "color(display-p3 0.118 0.077 0.121)",
  plum3: "color(display-p3 0.192 0.105 0.202)",
  plum4: "color(display-p3 0.25 0.121 0.271)",
  plum5: "color(display-p3 0.293 0.152 0.319)",
  plum6: "color(display-p3 0.343 0.198 0.372)",
  plum7: "color(display-p3 0.424 0.262 0.461)",
  plum8: "color(display-p3 0.54 0.341 0.595)",
  plum9: "color(display-p3 0.624 0.313 0.708)",
  plum10: "color(display-p3 0.666 0.365 0.748)",
  plum11: "color(display-p3 0.86 0.602 0.933)",
  plum12: "color(display-p3 0.936 0.836 0.949)"
}, k0 = {
  plumA1: "color(display-p3 0.973 0.071 0.973 / 0.026)",
  plumA2: "color(display-p3 0.933 0.267 1 / 0.059)",
  plumA3: "color(display-p3 0.918 0.333 0.996 / 0.148)",
  plumA4: "color(display-p3 0.91 0.318 1 / 0.219)",
  plumA5: "color(display-p3 0.914 0.388 1 / 0.269)",
  plumA6: "color(display-p3 0.906 0.463 1 / 0.328)",
  plumA7: "color(display-p3 0.906 0.529 1 / 0.425)",
  plumA8: "color(display-p3 0.906 0.553 1 / 0.568)",
  plumA9: "color(display-p3 0.875 0.427 1 / 0.69)",
  plumA10: "color(display-p3 0.886 0.471 0.996 / 0.732)",
  plumA11: "color(display-p3 0.86 0.602 0.933)",
  plumA12: "color(display-p3 0.936 0.836 0.949)"
}, v0 = {
  purple1: "#18111b",
  purple2: "#1e1523",
  purple3: "#301c3b",
  purple4: "#3d224e",
  purple5: "#48295c",
  purple6: "#54346b",
  purple7: "#664282",
  purple8: "#8457aa",
  purple9: "#8e4ec6",
  purple10: "#9a5cd0",
  purple11: "#d19dff",
  purple12: "#ecd9fa"
}, w0 = {
  purpleA1: "#b412f90b",
  purpleA2: "#b744f714",
  purpleA3: "#c150ff2d",
  purpleA4: "#bb53fd42",
  purpleA5: "#be5cfd51",
  purpleA6: "#c16dfd61",
  purpleA7: "#c378fd7a",
  purpleA8: "#c47effa4",
  purpleA9: "#b661ffc2",
  purpleA10: "#bc6fffcd",
  purpleA11: "#d19dff",
  purpleA12: "#f1ddfffa"
}, P0 = {
  purple1: "color(display-p3 0.09 0.068 0.103)",
  purple2: "color(display-p3 0.113 0.082 0.134)",
  purple3: "color(display-p3 0.175 0.112 0.224)",
  purple4: "color(display-p3 0.224 0.137 0.297)",
  purple5: "color(display-p3 0.264 0.167 0.349)",
  purple6: "color(display-p3 0.311 0.208 0.406)",
  purple7: "color(display-p3 0.381 0.266 0.496)",
  purple8: "color(display-p3 0.49 0.349 0.649)",
  purple9: "color(display-p3 0.523 0.318 0.751)",
  purple10: "color(display-p3 0.57 0.373 0.791)",
  purple11: "color(display-p3 0.8 0.62 1)",
  purple12: "color(display-p3 0.913 0.854 0.971)"
}, D0 = {
  purpleA1: "color(display-p3 0.686 0.071 0.996 / 0.038)",
  purpleA2: "color(display-p3 0.722 0.286 0.996 / 0.072)",
  purpleA3: "color(display-p3 0.718 0.349 0.996 / 0.169)",
  purpleA4: "color(display-p3 0.702 0.353 1 / 0.248)",
  purpleA5: "color(display-p3 0.718 0.404 1 / 0.303)",
  purpleA6: "color(display-p3 0.733 0.455 1 / 0.366)",
  purpleA7: "color(display-p3 0.753 0.506 1 / 0.458)",
  purpleA8: "color(display-p3 0.749 0.522 1 / 0.622)",
  purpleA9: "color(display-p3 0.686 0.408 1 / 0.736)",
  purpleA10: "color(display-p3 0.71 0.459 1 / 0.778)",
  purpleA11: "color(display-p3 0.8 0.62 1)",
  purpleA12: "color(display-p3 0.913 0.854 0.971)"
}, j0 = {
  violet1: "#14121f",
  violet2: "#1b1525",
  violet3: "#291f43",
  violet4: "#33255b",
  violet5: "#3c2e69",
  violet6: "#473876",
  violet7: "#56468b",
  violet8: "#6958ad",
  violet9: "#6e56cf",
  violet10: "#7d66d9",
  violet11: "#baa7ff",
  violet12: "#e2ddfe"
}, z0 = {
  violetA1: "#4422ff0f",
  violetA2: "#853ff916",
  violetA3: "#8354fe36",
  violetA4: "#7d51fd50",
  violetA5: "#845ffd5f",
  violetA6: "#8f6cfd6d",
  violetA7: "#9879ff83",
  violetA8: "#977dfea8",
  violetA9: "#8668ffcc",
  violetA10: "#9176fed7",
  violetA11: "#baa7ff",
  violetA12: "#e3defffe"
}, $0 = {
  violet1: "color(display-p3 0.077 0.071 0.118)",
  violet2: "color(display-p3 0.101 0.084 0.141)",
  violet3: "color(display-p3 0.154 0.123 0.256)",
  violet4: "color(display-p3 0.191 0.148 0.345)",
  violet5: "color(display-p3 0.226 0.182 0.396)",
  violet6: "color(display-p3 0.269 0.223 0.449)",
  violet7: "color(display-p3 0.326 0.277 0.53)",
  violet8: "color(display-p3 0.399 0.346 0.656)",
  violet9: "color(display-p3 0.417 0.341 0.784)",
  violet10: "color(display-p3 0.477 0.402 0.823)",
  violet11: "color(display-p3 0.72 0.65 1)",
  violet12: "color(display-p3 0.883 0.867 0.986)"
}, h0 = {
  violetA1: "color(display-p3 0.282 0.141 0.996 / 0.055)",
  violetA2: "color(display-p3 0.51 0.263 1 / 0.08)",
  violetA3: "color(display-p3 0.494 0.337 0.996 / 0.202)",
  violetA4: "color(display-p3 0.49 0.345 1 / 0.299)",
  violetA5: "color(display-p3 0.525 0.392 1 / 0.353)",
  violetA6: "color(display-p3 0.569 0.455 1 / 0.408)",
  violetA7: "color(display-p3 0.588 0.494 1 / 0.496)",
  violetA8: "color(display-p3 0.596 0.51 1 / 0.631)",
  violetA9: "color(display-p3 0.522 0.424 1 / 0.769)",
  violetA10: "color(display-p3 0.576 0.482 1 / 0.811)",
  violetA11: "color(display-p3 0.72 0.65 1)",
  violetA12: "color(display-p3 0.883 0.867 0.986)"
}, C0 = {
  iris1: "#13131e",
  iris2: "#171625",
  iris3: "#202248",
  iris4: "#262a65",
  iris5: "#303374",
  iris6: "#3d3e82",
  iris7: "#4a4a95",
  iris8: "#5958b1",
  iris9: "#5b5bd6",
  iris10: "#6e6ade",
  iris11: "#b1a9ff",
  iris12: "#e0dffe"
}, _0 = {
  irisA1: "#3636fe0e",
  irisA2: "#564bf916",
  irisA3: "#525bff3b",
  irisA4: "#4d58ff5a",
  irisA5: "#5b62fd6b",
  irisA6: "#6d6ffd7a",
  irisA7: "#7777fe8e",
  irisA8: "#7b7afeac",
  irisA9: "#6a6afed4",
  irisA10: "#7d79ffdc",
  irisA11: "#b1a9ff",
  irisA12: "#e1e0fffe"
}, N0 = {
  iris1: "color(display-p3 0.075 0.075 0.114)",
  iris2: "color(display-p3 0.089 0.086 0.14)",
  iris3: "color(display-p3 0.128 0.134 0.272)",
  iris4: "color(display-p3 0.153 0.165 0.382)",
  iris5: "color(display-p3 0.192 0.201 0.44)",
  iris6: "color(display-p3 0.239 0.241 0.491)",
  iris7: "color(display-p3 0.291 0.289 0.565)",
  iris8: "color(display-p3 0.35 0.345 0.673)",
  iris9: "color(display-p3 0.357 0.357 0.81)",
  iris10: "color(display-p3 0.428 0.416 0.843)",
  iris11: "color(display-p3 0.685 0.662 1)",
  iris12: "color(display-p3 0.878 0.875 0.986)"
}, O0 = {
  irisA1: "color(display-p3 0.224 0.224 0.992 / 0.051)",
  irisA2: "color(display-p3 0.361 0.314 1 / 0.08)",
  irisA3: "color(display-p3 0.357 0.373 1 / 0.219)",
  irisA4: "color(display-p3 0.325 0.361 1 / 0.337)",
  irisA5: "color(display-p3 0.38 0.4 1 / 0.4)",
  irisA6: "color(display-p3 0.447 0.447 1 / 0.454)",
  irisA7: "color(display-p3 0.486 0.486 1 / 0.534)",
  irisA8: "color(display-p3 0.502 0.494 1 / 0.652)",
  irisA9: "color(display-p3 0.431 0.431 1 / 0.799)",
  irisA10: "color(display-p3 0.502 0.486 1 / 0.832)",
  irisA11: "color(display-p3 0.685 0.662 1)",
  irisA12: "color(display-p3 0.878 0.875 0.986)"
}, x0 = {
  indigo1: "#11131f",
  indigo2: "#141726",
  indigo3: "#182449",
  indigo4: "#1d2e62",
  indigo5: "#253974",
  indigo6: "#304384",
  indigo7: "#3a4f97",
  indigo8: "#435db1",
  indigo9: "#3e63dd",
  indigo10: "#5472e4",
  indigo11: "#9eb1ff",
  indigo12: "#d6e1ff"
}, S0 = {
  indigoA1: "#1133ff0f",
  indigoA2: "#3354fa17",
  indigoA3: "#2f62ff3c",
  indigoA4: "#3566ff57",
  indigoA5: "#4171fd6b",
  indigoA6: "#5178fd7c",
  indigoA7: "#5a7fff90",
  indigoA8: "#5b81feac",
  indigoA9: "#4671ffdb",
  indigoA10: "#5c7efee3",
  indigoA11: "#9eb1ff",
  indigoA12: "#d6e1ff"
}, M0 = {
  indigo1: "color(display-p3 0.068 0.074 0.118)",
  indigo2: "color(display-p3 0.081 0.089 0.144)",
  indigo3: "color(display-p3 0.105 0.141 0.275)",
  indigo4: "color(display-p3 0.129 0.18 0.369)",
  indigo5: "color(display-p3 0.163 0.22 0.439)",
  indigo6: "color(display-p3 0.203 0.262 0.5)",
  indigo7: "color(display-p3 0.245 0.309 0.575)",
  indigo8: "color(display-p3 0.285 0.362 0.674)",
  indigo9: "color(display-p3 0.276 0.384 0.837)",
  indigo10: "color(display-p3 0.354 0.445 0.866)",
  indigo11: "color(display-p3 0.63 0.69 1)",
  indigo12: "color(display-p3 0.848 0.881 0.99)"
}, F0 = {
  indigoA1: "color(display-p3 0.071 0.212 0.996 / 0.055)",
  indigoA2: "color(display-p3 0.251 0.345 0.988 / 0.085)",
  indigoA3: "color(display-p3 0.243 0.404 1 / 0.223)",
  indigoA4: "color(display-p3 0.263 0.42 1 / 0.324)",
  indigoA5: "color(display-p3 0.314 0.451 1 / 0.4)",
  indigoA6: "color(display-p3 0.361 0.49 1 / 0.467)",
  indigoA7: "color(display-p3 0.388 0.51 1 / 0.547)",
  indigoA8: "color(display-p3 0.404 0.518 1 / 0.652)",
  indigoA9: "color(display-p3 0.318 0.451 1 / 0.824)",
  indigoA10: "color(display-p3 0.404 0.506 1 / 0.858)",
  indigoA11: "color(display-p3 0.63 0.69 1)",
  indigoA12: "color(display-p3 0.848 0.881 0.99)"
}, I0 = {
  blue1: "#0d1520",
  blue2: "#111927",
  blue3: "#0d2847",
  blue4: "#003362",
  blue5: "#004074",
  blue6: "#104d87",
  blue7: "#205d9e",
  blue8: "#2870bd",
  blue9: "#0090ff",
  blue10: "#3b9eff",
  blue11: "#70b8ff",
  blue12: "#c2e6ff"
}, E0 = {
  blueA1: "#004df211",
  blueA2: "#1166fb18",
  blueA3: "#0077ff3a",
  blueA4: "#0075ff57",
  blueA5: "#0081fd6b",
  blueA6: "#0f89fd7f",
  blueA7: "#2a91fe98",
  blueA8: "#3094feb9",
  blueA9: "#0090ff",
  blueA10: "#3b9eff",
  blueA11: "#70b8ff",
  blueA12: "#c2e6ff"
}, L0 = {
  blue1: "color(display-p3 0.057 0.081 0.122)",
  blue2: "color(display-p3 0.072 0.098 0.147)",
  blue3: "color(display-p3 0.078 0.154 0.27)",
  blue4: "color(display-p3 0.033 0.197 0.37)",
  blue5: "color(display-p3 0.08 0.245 0.441)",
  blue6: "color(display-p3 0.14 0.298 0.511)",
  blue7: "color(display-p3 0.195 0.361 0.6)",
  blue8: "color(display-p3 0.239 0.434 0.72)",
  blue9: "color(display-p3 0.247 0.556 0.969)",
  blue10: "color(display-p3 0.344 0.612 0.973)",
  blue11: "color(display-p3 0.49 0.72 1)",
  blue12: "color(display-p3 0.788 0.898 0.99)"
}, q0 = {
  blueA1: "color(display-p3 0 0.333 1 / 0.059)",
  blueA2: "color(display-p3 0.114 0.435 0.988 / 0.085)",
  blueA3: "color(display-p3 0.122 0.463 1 / 0.219)",
  blueA4: "color(display-p3 0 0.467 1 / 0.324)",
  blueA5: "color(display-p3 0.098 0.51 1 / 0.4)",
  blueA6: "color(display-p3 0.224 0.557 1 / 0.475)",
  blueA7: "color(display-p3 0.294 0.584 1 / 0.572)",
  blueA8: "color(display-p3 0.314 0.592 1 / 0.702)",
  blueA9: "color(display-p3 0.251 0.573 0.996 / 0.967)",
  blueA10: "color(display-p3 0.357 0.631 1 / 0.971)",
  blueA11: "color(display-p3 0.49 0.72 1)",
  blueA12: "color(display-p3 0.788 0.898 0.99)"
}, T0 = {
  cyan1: "#0b161a",
  cyan2: "#101b20",
  cyan3: "#082c36",
  cyan4: "#003848",
  cyan5: "#004558",
  cyan6: "#045468",
  cyan7: "#12677e",
  cyan8: "#11809c",
  cyan9: "#00a2c7",
  cyan10: "#23afd0",
  cyan11: "#4ccce6",
  cyan12: "#b6ecf7"
}, V0 = {
  cyanA1: "#0091f70a",
  cyanA2: "#02a7f211",
  cyanA3: "#00befd28",
  cyanA4: "#00baff3b",
  cyanA5: "#00befd4d",
  cyanA6: "#00c7fd5e",
  cyanA7: "#14cdff75",
  cyanA8: "#11cfff95",
  cyanA9: "#00cfffc3",
  cyanA10: "#28d6ffcd",
  cyanA11: "#52e1fee5",
  cyanA12: "#bbf3fef7"
}, B0 = {
  cyan1: "color(display-p3 0.053 0.085 0.098)",
  cyan2: "color(display-p3 0.072 0.105 0.122)",
  cyan3: "color(display-p3 0.073 0.168 0.209)",
  cyan4: "color(display-p3 0.063 0.216 0.277)",
  cyan5: "color(display-p3 0.091 0.267 0.336)",
  cyan6: "color(display-p3 0.137 0.324 0.4)",
  cyan7: "color(display-p3 0.186 0.398 0.484)",
  cyan8: "color(display-p3 0.23 0.496 0.6)",
  cyan9: "color(display-p3 0.282 0.627 0.765)",
  cyan10: "color(display-p3 0.331 0.675 0.801)",
  cyan11: "color(display-p3 0.446 0.79 0.887)",
  cyan12: "color(display-p3 0.757 0.919 0.962)"
}, H0 = {
  cyanA1: "color(display-p3 0 0.647 0.992 / 0.034)",
  cyanA2: "color(display-p3 0.133 0.733 1 / 0.059)",
  cyanA3: "color(display-p3 0.122 0.741 0.996 / 0.152)",
  cyanA4: "color(display-p3 0.051 0.725 1 / 0.227)",
  cyanA5: "color(display-p3 0.149 0.757 1 / 0.29)",
  cyanA6: "color(display-p3 0.267 0.792 1 / 0.358)",
  cyanA7: "color(display-p3 0.333 0.808 1 / 0.446)",
  cyanA8: "color(display-p3 0.357 0.816 1 / 0.572)",
  cyanA9: "color(display-p3 0.357 0.82 1 / 0.748)",
  cyanA10: "color(display-p3 0.4 0.839 1 / 0.786)",
  cyanA11: "color(display-p3 0.446 0.79 0.887)",
  cyanA12: "color(display-p3 0.757 0.919 0.962)"
}, J0 = {
  teal1: "#0d1514",
  teal2: "#111c1b",
  teal3: "#0d2d2a",
  teal4: "#023b37",
  teal5: "#084843",
  teal6: "#145750",
  teal7: "#1c6961",
  teal8: "#207e73",
  teal9: "#12a594",
  teal10: "#0eb39e",
  teal11: "#0bd8b6",
  teal12: "#adf0dd"
}, K0 = {
  tealA1: "#00deab05",
  tealA2: "#12fbe60c",
  tealA3: "#00ffe61e",
  tealA4: "#00ffe92d",
  tealA5: "#00ffea3b",
  tealA6: "#1cffe84b",
  tealA7: "#2efde85f",
  tealA8: "#32ffe775",
  tealA9: "#13ffe49f",
  tealA10: "#0dffe0ae",
  tealA11: "#0afed5d6",
  tealA12: "#b8ffebef"
}, Q0 = {
  teal1: "color(display-p3 0.059 0.083 0.079)",
  teal2: "color(display-p3 0.075 0.11 0.107)",
  teal3: "color(display-p3 0.087 0.175 0.165)",
  teal4: "color(display-p3 0.087 0.227 0.214)",
  teal5: "color(display-p3 0.12 0.277 0.261)",
  teal6: "color(display-p3 0.162 0.335 0.314)",
  teal7: "color(display-p3 0.205 0.406 0.379)",
  teal8: "color(display-p3 0.245 0.489 0.453)",
  teal9: "color(display-p3 0.297 0.637 0.581)",
  teal10: "color(display-p3 0.319 0.69 0.62)",
  teal11: "color(display-p3 0.388 0.835 0.719)",
  teal12: "color(display-p3 0.734 0.934 0.87)"
}, R0 = {
  tealA1: "color(display-p3 0 0.992 0.761 / 0.017)",
  tealA2: "color(display-p3 0.235 0.988 0.902 / 0.047)",
  tealA3: "color(display-p3 0.235 1 0.898 / 0.118)",
  tealA4: "color(display-p3 0.18 0.996 0.929 / 0.173)",
  tealA5: "color(display-p3 0.31 1 0.933 / 0.227)",
  tealA6: "color(display-p3 0.396 1 0.933 / 0.286)",
  tealA7: "color(display-p3 0.443 1 0.925 / 0.366)",
  tealA8: "color(display-p3 0.459 1 0.925 / 0.454)",
  tealA9: "color(display-p3 0.443 0.996 0.906 / 0.61)",
  tealA10: "color(display-p3 0.439 0.996 0.89 / 0.669)",
  tealA11: "color(display-p3 0.388 0.835 0.719)",
  tealA12: "color(display-p3 0.734 0.934 0.87)"
}, U0 = {
  jade1: "#0d1512",
  jade2: "#121c18",
  jade3: "#0f2e22",
  jade4: "#0b3b2c",
  jade5: "#114837",
  jade6: "#1b5745",
  jade7: "#246854",
  jade8: "#2a7e68",
  jade9: "#29a383",
  jade10: "#27b08b",
  jade11: "#1fd8a4",
  jade12: "#adf0d4"
}, W0 = {
  jadeA1: "#00de4505",
  jadeA2: "#27fba60c",
  jadeA3: "#02f99920",
  jadeA4: "#00ffaa2d",
  jadeA5: "#11ffb63b",
  jadeA6: "#34ffc24b",
  jadeA7: "#45fdc75e",
  jadeA8: "#48ffcf75",
  jadeA9: "#38feca9d",
  jadeA10: "#31fec7ab",
  jadeA11: "#21fec0d6",
  jadeA12: "#b8ffe1ef"
}, X0 = {
  jade1: "color(display-p3 0.059 0.083 0.071)",
  jade2: "color(display-p3 0.078 0.11 0.094)",
  jade3: "color(display-p3 0.091 0.176 0.138)",
  jade4: "color(display-p3 0.102 0.228 0.177)",
  jade5: "color(display-p3 0.133 0.279 0.221)",
  jade6: "color(display-p3 0.174 0.334 0.273)",
  jade7: "color(display-p3 0.219 0.402 0.335)",
  jade8: "color(display-p3 0.263 0.488 0.411)",
  jade9: "color(display-p3 0.319 0.63 0.521)",
  jade10: "color(display-p3 0.338 0.68 0.555)",
  jade11: "color(display-p3 0.4 0.835 0.656)",
  jade12: "color(display-p3 0.734 0.934 0.838)"
}, Y0 = {
  jadeA1: "color(display-p3 0 0.992 0.298 / 0.017)",
  jadeA2: "color(display-p3 0.318 0.988 0.651 / 0.047)",
  jadeA3: "color(display-p3 0.267 1 0.667 / 0.118)",
  jadeA4: "color(display-p3 0.275 0.996 0.702 / 0.173)",
  jadeA5: "color(display-p3 0.361 1 0.741 / 0.227)",
  jadeA6: "color(display-p3 0.439 1 0.796 / 0.286)",
  jadeA7: "color(display-p3 0.49 1 0.804 / 0.362)",
  jadeA8: "color(display-p3 0.506 1 0.835 / 0.45)",
  jadeA9: "color(display-p3 0.478 0.996 0.816 / 0.606)",
  jadeA10: "color(display-p3 0.478 1 0.816 / 0.656)",
  jadeA11: "color(display-p3 0.4 0.835 0.656)",
  jadeA12: "color(display-p3 0.734 0.934 0.838)"
}, Z0 = {
  green1: "#0e1512",
  green2: "#121b17",
  green3: "#132d21",
  green4: "#113b29",
  green5: "#174933",
  green6: "#20573e",
  green7: "#28684a",
  green8: "#2f7c57",
  green9: "#30a46c",
  green10: "#33b074",
  green11: "#3dd68c",
  green12: "#b1f1cb"
}, G0 = {
  greenA1: "#00de4505",
  greenA2: "#29f99d0b",
  greenA3: "#22ff991e",
  greenA4: "#11ff992d",
  greenA5: "#2bffa23c",
  greenA6: "#44ffaa4b",
  greenA7: "#50fdac5e",
  greenA8: "#54ffad73",
  greenA9: "#44ffa49e",
  greenA10: "#43fea4ab",
  greenA11: "#46fea5d4",
  greenA12: "#bbffd7f0"
}, oo = {
  green1: "color(display-p3 0.062 0.083 0.071)",
  green2: "color(display-p3 0.079 0.106 0.09)",
  green3: "color(display-p3 0.1 0.173 0.133)",
  green4: "color(display-p3 0.115 0.229 0.166)",
  green5: "color(display-p3 0.147 0.282 0.206)",
  green6: "color(display-p3 0.185 0.338 0.25)",
  green7: "color(display-p3 0.227 0.403 0.298)",
  green8: "color(display-p3 0.27 0.479 0.351)",
  green9: "color(display-p3 0.332 0.634 0.442)",
  green10: "color(display-p3 0.357 0.682 0.474)",
  green11: "color(display-p3 0.434 0.828 0.573)",
  green12: "color(display-p3 0.747 0.938 0.807)"
}, lo = {
  greenA1: "color(display-p3 0 0.992 0.298 / 0.017)",
  greenA2: "color(display-p3 0.341 0.98 0.616 / 0.043)",
  greenA3: "color(display-p3 0.376 0.996 0.655 / 0.114)",
  greenA4: "color(display-p3 0.341 0.996 0.635 / 0.173)",
  greenA5: "color(display-p3 0.408 1 0.678 / 0.232)",
  greenA6: "color(display-p3 0.475 1 0.706 / 0.29)",
  greenA7: "color(display-p3 0.514 1 0.706 / 0.362)",
  greenA8: "color(display-p3 0.529 1 0.718 / 0.442)",
  greenA9: "color(display-p3 0.502 0.996 0.682 / 0.61)",
  greenA10: "color(display-p3 0.506 1 0.682 / 0.66)",
  greenA11: "color(display-p3 0.434 0.828 0.573)",
  greenA12: "color(display-p3 0.747 0.938 0.807)"
}, ao = {
  grass1: "#0e1511",
  grass2: "#141a15",
  grass3: "#1b2a1e",
  grass4: "#1d3a24",
  grass5: "#25482d",
  grass6: "#2d5736",
  grass7: "#366740",
  grass8: "#3e7949",
  grass9: "#46a758",
  grass10: "#53b365",
  grass11: "#71d083",
  grass12: "#c2f0c2"
}, po = {
  grassA1: "#00de1205",
  grassA2: "#5ef7780a",
  grassA3: "#70fe8c1b",
  grassA4: "#57ff802c",
  grassA5: "#68ff8b3b",
  grassA6: "#71ff8f4b",
  grassA7: "#77fd925d",
  grassA8: "#77fd9070",
  grassA9: "#65ff82a1",
  grassA10: "#72ff8dae",
  grassA11: "#89ff9fcd",
  grassA12: "#ceffceef"
}, ro = {
  grass1: "color(display-p3 0.062 0.083 0.067)",
  grass2: "color(display-p3 0.083 0.103 0.085)",
  grass3: "color(display-p3 0.118 0.163 0.122)",
  grass4: "color(display-p3 0.142 0.225 0.15)",
  grass5: "color(display-p3 0.178 0.279 0.186)",
  grass6: "color(display-p3 0.217 0.337 0.224)",
  grass7: "color(display-p3 0.258 0.4 0.264)",
  grass8: "color(display-p3 0.302 0.47 0.305)",
  grass9: "color(display-p3 0.38 0.647 0.378)",
  grass10: "color(display-p3 0.426 0.694 0.426)",
  grass11: "color(display-p3 0.535 0.807 0.542)",
  grass12: "color(display-p3 0.797 0.936 0.776)"
}, eo = {
  grassA1: "color(display-p3 0 0.992 0.071 / 0.017)",
  grassA2: "color(display-p3 0.482 0.996 0.584 / 0.038)",
  grassA3: "color(display-p3 0.549 0.992 0.588 / 0.106)",
  grassA4: "color(display-p3 0.51 0.996 0.557 / 0.169)",
  grassA5: "color(display-p3 0.553 1 0.588 / 0.227)",
  grassA6: "color(display-p3 0.584 1 0.608 / 0.29)",
  grassA7: "color(display-p3 0.604 1 0.616 / 0.358)",
  grassA8: "color(display-p3 0.608 1 0.62 / 0.433)",
  grassA9: "color(display-p3 0.573 1 0.569 / 0.622)",
  grassA10: "color(display-p3 0.6 0.996 0.6 / 0.673)",
  grassA11: "color(display-p3 0.535 0.807 0.542)",
  grassA12: "color(display-p3 0.797 0.936 0.776)"
}, so = {
  brown1: "#12110f",
  brown2: "#1c1816",
  brown3: "#28211d",
  brown4: "#322922",
  brown5: "#3e3128",
  brown6: "#4d3c2f",
  brown7: "#614a39",
  brown8: "#7c5f46",
  brown9: "#ad7f58",
  brown10: "#b88c67",
  brown11: "#dbb594",
  brown12: "#f2e1ca"
}, io = {
  brownA1: "#91110002",
  brownA2: "#fba67c0c",
  brownA3: "#fcb58c19",
  brownA4: "#fbbb8a24",
  brownA5: "#fcb88931",
  brownA6: "#fdba8741",
  brownA7: "#ffbb8856",
  brownA8: "#ffbe8773",
  brownA9: "#feb87da8",
  brownA10: "#ffc18cb3",
  brownA11: "#fed1aad9",
  brownA12: "#feecd4f2"
}, co = {
  brown1: "color(display-p3 0.071 0.067 0.059)",
  brown2: "color(display-p3 0.107 0.095 0.087)",
  brown3: "color(display-p3 0.151 0.13 0.115)",
  brown4: "color(display-p3 0.191 0.161 0.138)",
  brown5: "color(display-p3 0.235 0.194 0.162)",
  brown6: "color(display-p3 0.291 0.237 0.192)",
  brown7: "color(display-p3 0.365 0.295 0.232)",
  brown8: "color(display-p3 0.469 0.377 0.287)",
  brown9: "color(display-p3 0.651 0.505 0.368)",
  brown10: "color(display-p3 0.697 0.557 0.423)",
  brown11: "color(display-p3 0.835 0.715 0.597)",
  brown12: "color(display-p3 0.938 0.885 0.802)"
}, yo = {
  brownA1: "color(display-p3 0.855 0.071 0 / 0.005)",
  brownA2: "color(display-p3 0.98 0.706 0.525 / 0.043)",
  brownA3: "color(display-p3 0.996 0.745 0.576 / 0.093)",
  brownA4: "color(display-p3 1 0.765 0.592 / 0.135)",
  brownA5: "color(display-p3 1 0.761 0.588 / 0.181)",
  brownA6: "color(display-p3 1 0.773 0.592 / 0.24)",
  brownA7: "color(display-p3 0.996 0.776 0.58 / 0.32)",
  brownA8: "color(display-p3 1 0.78 0.573 / 0.433)",
  brownA9: "color(display-p3 1 0.769 0.549 / 0.627)",
  brownA10: "color(display-p3 1 0.792 0.596 / 0.677)",
  brownA11: "color(display-p3 0.835 0.715 0.597)",
  brownA12: "color(display-p3 0.938 0.885 0.802)"
}, Ao = {
  bronze1: "#141110",
  bronze2: "#1c1917",
  bronze3: "#262220",
  bronze4: "#302a27",
  bronze5: "#3b3330",
  bronze6: "#493e3a",
  bronze7: "#5a4c47",
  bronze8: "#6f5f58",
  bronze9: "#a18072",
  bronze10: "#ae8c7e",
  bronze11: "#d4b3a5",
  bronze12: "#ede0d9"
}, fo = {
  bronzeA1: "#d1110004",
  bronzeA2: "#fbbc910c",
  bronzeA3: "#faceb817",
  bronzeA4: "#facdb622",
  bronzeA5: "#ffd2c12d",
  bronzeA6: "#ffd1c03c",
  bronzeA7: "#fdd0c04f",
  bronzeA8: "#ffd6c565",
  bronzeA9: "#fec7b09b",
  bronzeA10: "#fecab5a9",
  bronzeA11: "#ffd7c6d1",
  bronzeA12: "#fff1e9ec"
}, no = {
  bronze1: "color(display-p3 0.076 0.067 0.063)",
  bronze2: "color(display-p3 0.106 0.097 0.093)",
  bronze3: "color(display-p3 0.147 0.132 0.125)",
  bronze4: "color(display-p3 0.185 0.166 0.156)",
  bronze5: "color(display-p3 0.227 0.202 0.19)",
  bronze6: "color(display-p3 0.278 0.246 0.23)",
  bronze7: "color(display-p3 0.343 0.302 0.281)",
  bronze8: "color(display-p3 0.426 0.374 0.347)",
  bronze9: "color(display-p3 0.611 0.507 0.455)",
  bronze10: "color(display-p3 0.66 0.556 0.504)",
  bronze11: "color(display-p3 0.81 0.707 0.655)",
  bronze12: "color(display-p3 0.921 0.88 0.854)"
}, bo = {
  bronzeA1: "color(display-p3 0.941 0.067 0 / 0.009)",
  bronzeA2: "color(display-p3 0.98 0.8 0.706 / 0.043)",
  bronzeA3: "color(display-p3 0.988 0.851 0.761 / 0.085)",
  bronzeA4: "color(display-p3 0.996 0.839 0.78 / 0.127)",
  bronzeA5: "color(display-p3 0.996 0.863 0.773 / 0.173)",
  bronzeA6: "color(display-p3 1 0.863 0.796 / 0.227)",
  bronzeA7: "color(display-p3 1 0.867 0.8 / 0.295)",
  bronzeA8: "color(display-p3 1 0.859 0.788 / 0.387)",
  bronzeA9: "color(display-p3 1 0.82 0.733 / 0.585)",
  bronzeA10: "color(display-p3 1 0.839 0.761 / 0.635)",
  bronzeA11: "color(display-p3 0.81 0.707 0.655)",
  bronzeA12: "color(display-p3 0.921 0.88 0.854)"
}, to = {
  gold1: "#121211",
  gold2: "#1b1a17",
  gold3: "#24231f",
  gold4: "#2d2b26",
  gold5: "#38352e",
  gold6: "#444039",
  gold7: "#544f46",
  gold8: "#696256",
  gold9: "#978365",
  gold10: "#a39073",
  gold11: "#cbb99f",
  gold12: "#e8e2d9"
}, go = {
  goldA1: "#91911102",
  goldA2: "#f9e29d0b",
  goldA3: "#f8ecbb15",
  goldA4: "#ffeec41e",
  goldA5: "#feecc22a",
  goldA6: "#feebcb37",
  goldA7: "#ffedcd48",
  goldA8: "#fdeaca5f",
  goldA9: "#ffdba690",
  goldA10: "#fedfb09d",
  goldA11: "#fee7c6c8",
  goldA12: "#fef7ede7"
}, mo = {
  gold1: "color(display-p3 0.071 0.071 0.067)",
  gold2: "color(display-p3 0.104 0.101 0.09)",
  gold3: "color(display-p3 0.141 0.136 0.122)",
  gold4: "color(display-p3 0.177 0.17 0.152)",
  gold5: "color(display-p3 0.217 0.207 0.185)",
  gold6: "color(display-p3 0.265 0.252 0.225)",
  gold7: "color(display-p3 0.327 0.31 0.277)",
  gold8: "color(display-p3 0.407 0.384 0.342)",
  gold9: "color(display-p3 0.579 0.517 0.41)",
  gold10: "color(display-p3 0.628 0.566 0.463)",
  gold11: "color(display-p3 0.784 0.728 0.635)",
  gold12: "color(display-p3 0.906 0.887 0.855)"
}, uo = {
  goldA1: "color(display-p3 0.855 0.855 0.071 / 0.005)",
  goldA2: "color(display-p3 0.98 0.89 0.616 / 0.043)",
  goldA3: "color(display-p3 1 0.949 0.753 / 0.08)",
  goldA4: "color(display-p3 1 0.933 0.8 / 0.118)",
  goldA5: "color(display-p3 1 0.949 0.804 / 0.16)",
  goldA6: "color(display-p3 1 0.925 0.8 / 0.215)",
  goldA7: "color(display-p3 1 0.945 0.831 / 0.278)",
  goldA8: "color(display-p3 1 0.937 0.82 / 0.366)",
  goldA9: "color(display-p3 0.996 0.882 0.69 / 0.551)",
  goldA10: "color(display-p3 1 0.894 0.725 / 0.601)",
  goldA11: "color(display-p3 0.784 0.728 0.635)",
  goldA12: "color(display-p3 0.906 0.887 0.855)"
}, ko = {
  sky1: "#0d141f",
  sky2: "#111a27",
  sky3: "#112840",
  sky4: "#113555",
  sky5: "#154467",
  sky6: "#1b537b",
  sky7: "#1f6692",
  sky8: "#197cae",
  sky9: "#7ce2fe",
  sky10: "#a8eeff",
  sky11: "#75c7f0",
  sky12: "#c2f3ff"
}, vo = {
  skyA1: "#0044ff0f",
  skyA2: "#1171fb18",
  skyA3: "#1184fc33",
  skyA4: "#128fff49",
  skyA5: "#1c9dfd5d",
  skyA6: "#28a5ff72",
  skyA7: "#2badfe8b",
  skyA8: "#1db2fea9",
  skyA9: "#7ce3fffe",
  skyA10: "#a8eeff",
  skyA11: "#7cd3ffef",
  skyA12: "#c2f3ff"
}, wo = {
  sky1: "color(display-p3 0.056 0.078 0.116)",
  sky2: "color(display-p3 0.075 0.101 0.149)",
  sky3: "color(display-p3 0.089 0.154 0.244)",
  sky4: "color(display-p3 0.106 0.207 0.323)",
  sky5: "color(display-p3 0.135 0.261 0.394)",
  sky6: "color(display-p3 0.17 0.322 0.469)",
  sky7: "color(display-p3 0.205 0.394 0.557)",
  sky8: "color(display-p3 0.232 0.48 0.665)",
  sky9: "color(display-p3 0.585 0.877 0.983)",
  sky10: "color(display-p3 0.718 0.925 0.991)",
  sky11: "color(display-p3 0.536 0.772 0.924)",
  sky12: "color(display-p3 0.799 0.947 0.993)"
}, Po = {
  skyA1: "color(display-p3 0 0.282 0.996 / 0.055)",
  skyA2: "color(display-p3 0.157 0.467 0.992 / 0.089)",
  skyA3: "color(display-p3 0.192 0.522 0.996 / 0.19)",
  skyA4: "color(display-p3 0.212 0.584 1 / 0.274)",
  skyA5: "color(display-p3 0.259 0.631 1 / 0.349)",
  skyA6: "color(display-p3 0.302 0.655 1 / 0.433)",
  skyA7: "color(display-p3 0.329 0.686 1 / 0.526)",
  skyA8: "color(display-p3 0.325 0.71 1 / 0.643)",
  skyA9: "color(display-p3 0.592 0.894 1 / 0.984)",
  skyA10: "color(display-p3 0.722 0.933 1 / 0.992)",
  skyA11: "color(display-p3 0.536 0.772 0.924)",
  skyA12: "color(display-p3 0.799 0.947 0.993)"
}, Do = {
  mint1: "#0e1515",
  mint2: "#0f1b1b",
  mint3: "#092c2b",
  mint4: "#003a38",
  mint5: "#004744",
  mint6: "#105650",
  mint7: "#1e685f",
  mint8: "#277f70",
  mint9: "#86ead4",
  mint10: "#a8f5e5",
  mint11: "#58d5ba",
  mint12: "#c4f5e1"
}, jo = {
  mintA1: "#00dede05",
  mintA2: "#00f9f90b",
  mintA3: "#00fff61d",
  mintA4: "#00fff42c",
  mintA5: "#00fff23a",
  mintA6: "#0effeb4a",
  mintA7: "#34fde55e",
  mintA8: "#41ffdf76",
  mintA9: "#92ffe7e9",
  mintA10: "#aefeedf5",
  mintA11: "#67ffded2",
  mintA12: "#cbfee9f5"
}, zo = {
  mint1: "color(display-p3 0.059 0.082 0.081)",
  mint2: "color(display-p3 0.068 0.104 0.105)",
  mint3: "color(display-p3 0.077 0.17 0.168)",
  mint4: "color(display-p3 0.068 0.224 0.22)",
  mint5: "color(display-p3 0.104 0.275 0.264)",
  mint6: "color(display-p3 0.154 0.332 0.313)",
  mint7: "color(display-p3 0.207 0.403 0.373)",
  mint8: "color(display-p3 0.258 0.49 0.441)",
  mint9: "color(display-p3 0.62 0.908 0.834)",
  mint10: "color(display-p3 0.725 0.954 0.898)",
  mint11: "color(display-p3 0.482 0.825 0.733)",
  mint12: "color(display-p3 0.807 0.955 0.887)"
}, $o = {
  mintA1: "color(display-p3 0 0.992 0.992 / 0.017)",
  mintA2: "color(display-p3 0.071 0.98 0.98 / 0.043)",
  mintA3: "color(display-p3 0.176 0.996 0.996 / 0.11)",
  mintA4: "color(display-p3 0.071 0.996 0.973 / 0.169)",
  mintA5: "color(display-p3 0.243 1 0.949 / 0.223)",
  mintA6: "color(display-p3 0.369 1 0.933 / 0.286)",
  mintA7: "color(display-p3 0.459 1 0.914 / 0.362)",
  mintA8: "color(display-p3 0.49 1 0.89 / 0.454)",
  mintA9: "color(display-p3 0.678 0.996 0.914 / 0.904)",
  mintA10: "color(display-p3 0.761 1 0.941 / 0.95)",
  mintA11: "color(display-p3 0.482 0.825 0.733)",
  mintA12: "color(display-p3 0.807 0.955 0.887)"
}, ho = {
  lime1: "#11130c",
  lime2: "#151a10",
  lime3: "#1f2917",
  lime4: "#29371d",
  lime5: "#334423",
  lime6: "#3d522a",
  lime7: "#496231",
  lime8: "#577538",
  lime9: "#bdee63",
  lime10: "#d4ff70",
  lime11: "#bde56c",
  lime12: "#e3f7ba"
}, Co = {
  limeA1: "#11bb0003",
  limeA2: "#78f7000a",
  limeA3: "#9bfd4c1a",
  limeA4: "#a7fe5c29",
  limeA5: "#affe6537",
  limeA6: "#b2fe6d46",
  limeA7: "#b6ff6f57",
  limeA8: "#b6fd6d6c",
  limeA9: "#caff69ed",
  limeA10: "#d4ff70",
  limeA11: "#d1fe77e4",
  limeA12: "#e9febff7"
}, _o = {
  lime1: "color(display-p3 0.067 0.073 0.048)",
  lime2: "color(display-p3 0.086 0.1 0.067)",
  lime3: "color(display-p3 0.13 0.16 0.099)",
  lime4: "color(display-p3 0.172 0.214 0.126)",
  lime5: "color(display-p3 0.213 0.266 0.153)",
  lime6: "color(display-p3 0.257 0.321 0.182)",
  lime7: "color(display-p3 0.307 0.383 0.215)",
  lime8: "color(display-p3 0.365 0.456 0.25)",
  lime9: "color(display-p3 0.78 0.928 0.466)",
  lime10: "color(display-p3 0.865 0.995 0.519)",
  lime11: "color(display-p3 0.771 0.893 0.485)",
  lime12: "color(display-p3 0.905 0.966 0.753)"
}, No = {
  limeA1: "color(display-p3 0.067 0.941 0 / 0.009)",
  limeA2: "color(display-p3 0.584 0.996 0.071 / 0.038)",
  limeA3: "color(display-p3 0.69 1 0.38 / 0.101)",
  limeA4: "color(display-p3 0.729 1 0.435 / 0.16)",
  limeA5: "color(display-p3 0.745 1 0.471 / 0.215)",
  limeA6: "color(display-p3 0.769 1 0.482 / 0.274)",
  limeA7: "color(display-p3 0.769 1 0.506 / 0.341)",
  limeA8: "color(display-p3 0.784 1 0.51 / 0.416)",
  limeA9: "color(display-p3 0.839 1 0.502 / 0.925)",
  limeA10: "color(display-p3 0.871 1 0.522 / 0.996)",
  limeA11: "color(display-p3 0.771 0.893 0.485)",
  limeA12: "color(display-p3 0.905 0.966 0.753)"
}, Oo = {
  yellow1: "#14120b",
  yellow2: "#1b180f",
  yellow3: "#2d2305",
  yellow4: "#362b00",
  yellow5: "#433500",
  yellow6: "#524202",
  yellow7: "#665417",
  yellow8: "#836a21",
  yellow9: "#ffe629",
  yellow10: "#ffff57",
  yellow11: "#f5e147",
  yellow12: "#f6eeb4"
}, xo = {
  yellowA1: "#d1510004",
  yellowA2: "#f9b4000b",
  yellowA3: "#ffaa001e",
  yellowA4: "#fdb70028",
  yellowA5: "#febb0036",
  yellowA6: "#fec40046",
  yellowA7: "#fdcb225c",
  yellowA8: "#fdca327b",
  yellowA9: "#ffe629",
  yellowA10: "#ffff57",
  yellowA11: "#fee949f5",
  yellowA12: "#fef6baf6"
}, So = {
  yellow1: "color(display-p3 0.078 0.069 0.047)",
  yellow2: "color(display-p3 0.103 0.094 0.063)",
  yellow3: "color(display-p3 0.168 0.137 0.039)",
  yellow4: "color(display-p3 0.209 0.169 0)",
  yellow5: "color(display-p3 0.255 0.209 0)",
  yellow6: "color(display-p3 0.31 0.261 0.07)",
  yellow7: "color(display-p3 0.389 0.331 0.135)",
  yellow8: "color(display-p3 0.497 0.42 0.182)",
  yellow9: "color(display-p3 1 0.92 0.22)",
  yellow10: "color(display-p3 1 1 0.456)",
  yellow11: "color(display-p3 0.948 0.885 0.392)",
  yellow12: "color(display-p3 0.959 0.934 0.731)"
}, Mo = {
  yellowA1: "color(display-p3 0.973 0.369 0 / 0.013)",
  yellowA2: "color(display-p3 0.996 0.792 0 / 0.038)",
  yellowA3: "color(display-p3 0.996 0.71 0 / 0.11)",
  yellowA4: "color(display-p3 0.996 0.741 0 / 0.152)",
  yellowA5: "color(display-p3 0.996 0.765 0 / 0.202)",
  yellowA6: "color(display-p3 0.996 0.816 0.082 / 0.261)",
  yellowA7: "color(display-p3 1 0.831 0.263 / 0.345)",
  yellowA8: "color(display-p3 1 0.831 0.314 / 0.463)",
  yellowA9: "color(display-p3 1 0.922 0.22)",
  yellowA10: "color(display-p3 1 1 0.455)",
  yellowA11: "color(display-p3 0.948 0.885 0.392)",
  yellowA12: "color(display-p3 0.959 0.934 0.731)"
}, Fo = {
  amber1: "#16120c",
  amber2: "#1d180f",
  amber3: "#302008",
  amber4: "#3f2700",
  amber5: "#4d3000",
  amber6: "#5c3d05",
  amber7: "#714f19",
  amber8: "#8f6424",
  amber9: "#ffc53d",
  amber10: "#ffd60a",
  amber11: "#ffca16",
  amber12: "#ffe7b3"
}, Io = {
  amberA1: "#e63c0006",
  amberA2: "#fd9b000d",
  amberA3: "#fa820022",
  amberA4: "#fc820032",
  amberA5: "#fd8b0041",
  amberA6: "#fd9b0051",
  amberA7: "#ffab2567",
  amberA8: "#ffae3587",
  amberA9: "#ffc53d",
  amberA10: "#ffd60a",
  amberA11: "#ffca16",
  amberA12: "#ffe7b3"
}, Eo = {
  amber1: "color(display-p3 0.082 0.07 0.05)",
  amber2: "color(display-p3 0.111 0.094 0.064)",
  amber3: "color(display-p3 0.178 0.128 0.049)",
  amber4: "color(display-p3 0.239 0.156 0)",
  amber5: "color(display-p3 0.29 0.193 0)",
  amber6: "color(display-p3 0.344 0.245 0.076)",
  amber7: "color(display-p3 0.422 0.314 0.141)",
  amber8: "color(display-p3 0.535 0.399 0.189)",
  amber9: "color(display-p3 1 0.77 0.26)",
  amber10: "color(display-p3 1 0.87 0.15)",
  amber11: "color(display-p3 1 0.8 0.29)",
  amber12: "color(display-p3 0.984 0.909 0.726)"
}, Lo = {
  amberA1: "color(display-p3 0.992 0.298 0 / 0.017)",
  amberA2: "color(display-p3 0.988 0.651 0 / 0.047)",
  amberA3: "color(display-p3 1 0.6 0 / 0.118)",
  amberA4: "color(display-p3 1 0.557 0 / 0.185)",
  amberA5: "color(display-p3 1 0.592 0 / 0.24)",
  amberA6: "color(display-p3 1 0.659 0.094 / 0.299)",
  amberA7: "color(display-p3 1 0.714 0.263 / 0.383)",
  amberA8: "color(display-p3 0.996 0.729 0.306 / 0.5)",
  amberA9: "color(display-p3 1 0.769 0.259)",
  amberA10: "color(display-p3 1 0.871 0.149)",
  amberA11: "color(display-p3 1 0.8 0.29)",
  amberA12: "color(display-p3 0.984 0.909 0.726)"
}, qo = {
  orange1: "#17120e",
  orange2: "#1e160f",
  orange3: "#331e0b",
  orange4: "#462100",
  orange5: "#562800",
  orange6: "#66350c",
  orange7: "#7e451d",
  orange8: "#a35829",
  orange9: "#f76b15",
  orange10: "#ff801f",
  orange11: "#ffa057",
  orange12: "#ffe0c2"
}, To = {
  orangeA1: "#ec360007",
  orangeA2: "#fe6d000e",
  orangeA3: "#fb6a0025",
  orangeA4: "#ff590039",
  orangeA5: "#ff61004a",
  orangeA6: "#fd75045c",
  orangeA7: "#ff832c75",
  orangeA8: "#fe84389d",
  orangeA9: "#fe6d15f7",
  orangeA10: "#ff801f",
  orangeA11: "#ffa057",
  orangeA12: "#ffe0c2"
}, Vo = {
  orange1: "color(display-p3 0.088 0.07 0.057)",
  orange2: "color(display-p3 0.113 0.089 0.061)",
  orange3: "color(display-p3 0.189 0.12 0.056)",
  orange4: "color(display-p3 0.262 0.132 0)",
  orange5: "color(display-p3 0.315 0.168 0.016)",
  orange6: "color(display-p3 0.376 0.219 0.088)",
  orange7: "color(display-p3 0.465 0.283 0.147)",
  orange8: "color(display-p3 0.601 0.359 0.201)",
  orange9: "color(display-p3 0.9 0.45 0.2)",
  orange10: "color(display-p3 0.98 0.51 0.23)",
  orange11: "color(display-p3 1 0.63 0.38)",
  orange12: "color(display-p3 0.98 0.883 0.775)"
}, Bo = {
  orangeA1: "color(display-p3 0.961 0.247 0 / 0.022)",
  orangeA2: "color(display-p3 0.992 0.529 0 / 0.051)",
  orangeA3: "color(display-p3 0.996 0.486 0 / 0.131)",
  orangeA4: "color(display-p3 0.996 0.384 0 / 0.211)",
  orangeA5: "color(display-p3 1 0.455 0 / 0.265)",
  orangeA6: "color(display-p3 1 0.529 0.129 / 0.332)",
  orangeA7: "color(display-p3 1 0.569 0.251 / 0.429)",
  orangeA8: "color(display-p3 1 0.584 0.302 / 0.572)",
  orangeA9: "color(display-p3 1 0.494 0.216 / 0.895)",
  orangeA10: "color(display-p3 1 0.522 0.235 / 0.979)",
  orangeA11: "color(display-p3 1 0.63 0.38)",
  orangeA12: "color(display-p3 0.98 0.883 0.775)"
}, Ho = {
  gray1: "#fcfcfc",
  gray2: "#f9f9f9",
  gray3: "#f0f0f0",
  gray4: "#e8e8e8",
  gray5: "#e0e0e0",
  gray6: "#d9d9d9",
  gray7: "#cecece",
  gray8: "#bbbbbb",
  gray9: "#8d8d8d",
  gray10: "#838383",
  gray11: "#646464",
  gray12: "#202020"
}, Jo = {
  grayA1: "#00000003",
  grayA2: "#00000006",
  grayA3: "#0000000f",
  grayA4: "#00000017",
  grayA5: "#0000001f",
  grayA6: "#00000026",
  grayA7: "#00000031",
  grayA8: "#00000044",
  grayA9: "#00000072",
  grayA10: "#0000007c",
  grayA11: "#0000009b",
  grayA12: "#000000df"
}, Ko = {
  gray1: "color(display-p3 0.988 0.988 0.988)",
  gray2: "color(display-p3 0.975 0.975 0.975)",
  gray3: "color(display-p3 0.939 0.939 0.939)",
  gray4: "color(display-p3 0.908 0.908 0.908)",
  gray5: "color(display-p3 0.88 0.88 0.88)",
  gray6: "color(display-p3 0.849 0.849 0.849)",
  gray7: "color(display-p3 0.807 0.807 0.807)",
  gray8: "color(display-p3 0.732 0.732 0.732)",
  gray9: "color(display-p3 0.553 0.553 0.553)",
  gray10: "color(display-p3 0.512 0.512 0.512)",
  gray11: "color(display-p3 0.392 0.392 0.392)",
  gray12: "color(display-p3 0.125 0.125 0.125)"
}, Qo = {
  grayA1: "color(display-p3 0 0 0 / 0.012)",
  grayA2: "color(display-p3 0 0 0 / 0.024)",
  grayA3: "color(display-p3 0 0 0 / 0.063)",
  grayA4: "color(display-p3 0 0 0 / 0.09)",
  grayA5: "color(display-p3 0 0 0 / 0.122)",
  grayA6: "color(display-p3 0 0 0 / 0.153)",
  grayA7: "color(display-p3 0 0 0 / 0.192)",
  grayA8: "color(display-p3 0 0 0 / 0.267)",
  grayA9: "color(display-p3 0 0 0 / 0.447)",
  grayA10: "color(display-p3 0 0 0 / 0.486)",
  grayA11: "color(display-p3 0 0 0 / 0.608)",
  grayA12: "color(display-p3 0 0 0 / 0.875)"
}, Ro = {
  mauve1: "#fdfcfd",
  mauve2: "#faf9fb",
  mauve3: "#f2eff3",
  mauve4: "#eae7ec",
  mauve5: "#e3dfe6",
  mauve6: "#dbd8e0",
  mauve7: "#d0cdd7",
  mauve8: "#bcbac7",
  mauve9: "#8e8c99",
  mauve10: "#84828e",
  mauve11: "#65636d",
  mauve12: "#211f26"
}, Uo = {
  mauveA1: "#55005503",
  mauveA2: "#2b005506",
  mauveA3: "#30004010",
  mauveA4: "#20003618",
  mauveA5: "#20003820",
  mauveA6: "#14003527",
  mauveA7: "#10003332",
  mauveA8: "#08003145",
  mauveA9: "#05001d73",
  mauveA10: "#0500197d",
  mauveA11: "#0400119c",
  mauveA12: "#020008e0"
}, Wo = {
  mauve1: "color(display-p3 0.991 0.988 0.992)",
  mauve2: "color(display-p3 0.98 0.976 0.984)",
  mauve3: "color(display-p3 0.946 0.938 0.952)",
  mauve4: "color(display-p3 0.915 0.906 0.925)",
  mauve5: "color(display-p3 0.886 0.876 0.901)",
  mauve6: "color(display-p3 0.856 0.846 0.875)",
  mauve7: "color(display-p3 0.814 0.804 0.84)",
  mauve8: "color(display-p3 0.735 0.728 0.777)",
  mauve9: "color(display-p3 0.555 0.549 0.596)",
  mauve10: "color(display-p3 0.514 0.508 0.552)",
  mauve11: "color(display-p3 0.395 0.388 0.424)",
  mauve12: "color(display-p3 0.128 0.122 0.147)"
}, Xo = {
  mauveA1: "color(display-p3 0.349 0.024 0.349 / 0.012)",
  mauveA2: "color(display-p3 0.184 0.024 0.349 / 0.024)",
  mauveA3: "color(display-p3 0.129 0.008 0.255 / 0.063)",
  mauveA4: "color(display-p3 0.094 0.012 0.216 / 0.095)",
  mauveA5: "color(display-p3 0.098 0.008 0.224 / 0.126)",
  mauveA6: "color(display-p3 0.055 0.004 0.18 / 0.153)",
  mauveA7: "color(display-p3 0.067 0.008 0.184 / 0.197)",
  mauveA8: "color(display-p3 0.02 0.004 0.176 / 0.271)",
  mauveA9: "color(display-p3 0.02 0.004 0.106 / 0.451)",
  mauveA10: "color(display-p3 0.012 0.004 0.09 / 0.491)",
  mauveA11: "color(display-p3 0.016 0 0.059 / 0.612)",
  mauveA12: "color(display-p3 0.008 0 0.027 / 0.879)"
}, Yo = {
  slate1: "#fcfcfd",
  slate2: "#f9f9fb",
  slate3: "#f0f0f3",
  slate4: "#e8e8ec",
  slate5: "#e0e1e6",
  slate6: "#d9d9e0",
  slate7: "#cdced6",
  slate8: "#b9bbc6",
  slate9: "#8b8d98",
  slate10: "#80838d",
  slate11: "#60646c",
  slate12: "#1c2024"
}, Zo = {
  slateA1: "#00005503",
  slateA2: "#00005506",
  slateA3: "#0000330f",
  slateA4: "#00002d17",
  slateA5: "#0009321f",
  slateA6: "#00002f26",
  slateA7: "#00062e32",
  slateA8: "#00083046",
  slateA9: "#00051d74",
  slateA10: "#00071b7f",
  slateA11: "#0007149f",
  slateA12: "#000509e3"
}, Go = {
  slate1: "color(display-p3 0.988 0.988 0.992)",
  slate2: "color(display-p3 0.976 0.976 0.984)",
  slate3: "color(display-p3 0.94 0.941 0.953)",
  slate4: "color(display-p3 0.908 0.909 0.925)",
  slate5: "color(display-p3 0.88 0.881 0.901)",
  slate6: "color(display-p3 0.85 0.852 0.876)",
  slate7: "color(display-p3 0.805 0.808 0.838)",
  slate8: "color(display-p3 0.727 0.733 0.773)",
  slate9: "color(display-p3 0.547 0.553 0.592)",
  slate10: "color(display-p3 0.503 0.512 0.549)",
  slate11: "color(display-p3 0.379 0.392 0.421)",
  slate12: "color(display-p3 0.113 0.125 0.14)"
}, ol = {
  slateA1: "color(display-p3 0.024 0.024 0.349 / 0.012)",
  slateA2: "color(display-p3 0.024 0.024 0.349 / 0.024)",
  slateA3: "color(display-p3 0.004 0.004 0.204 / 0.059)",
  slateA4: "color(display-p3 0.012 0.012 0.184 / 0.091)",
  slateA5: "color(display-p3 0.004 0.039 0.2 / 0.122)",
  slateA6: "color(display-p3 0.008 0.008 0.165 / 0.15)",
  slateA7: "color(display-p3 0.008 0.027 0.184 / 0.197)",
  slateA8: "color(display-p3 0.004 0.031 0.176 / 0.275)",
  slateA9: "color(display-p3 0.004 0.02 0.106 / 0.455)",
  slateA10: "color(display-p3 0.004 0.027 0.098 / 0.499)",
  slateA11: "color(display-p3 0 0.02 0.063 / 0.62)",
  slateA12: "color(display-p3 0 0.012 0.031 / 0.887)"
}, ll = {
  sage1: "#fbfdfc",
  sage2: "#f7f9f8",
  sage3: "#eef1f0",
  sage4: "#e6e9e8",
  sage5: "#dfe2e0",
  sage6: "#d7dad9",
  sage7: "#cbcfcd",
  sage8: "#b8bcba",
  sage9: "#868e8b",
  sage10: "#7c8481",
  sage11: "#5f6563",
  sage12: "#1a211e"
}, al = {
  sageA1: "#00804004",
  sageA2: "#00402008",
  sageA3: "#002d1e11",
  sageA4: "#001f1519",
  sageA5: "#00180820",
  sageA6: "#00140d28",
  sageA7: "#00140a34",
  sageA8: "#000f0847",
  sageA9: "#00110b79",
  sageA10: "#00100a83",
  sageA11: "#000a07a0",
  sageA12: "#000805e5"
}, pl = {
  sage1: "color(display-p3 0.986 0.992 0.988)",
  sage2: "color(display-p3 0.97 0.977 0.974)",
  sage3: "color(display-p3 0.935 0.944 0.94)",
  sage4: "color(display-p3 0.904 0.913 0.909)",
  sage5: "color(display-p3 0.875 0.885 0.88)",
  sage6: "color(display-p3 0.844 0.854 0.849)",
  sage7: "color(display-p3 0.8 0.811 0.806)",
  sage8: "color(display-p3 0.725 0.738 0.732)",
  sage9: "color(display-p3 0.531 0.556 0.546)",
  sage10: "color(display-p3 0.492 0.515 0.506)",
  sage11: "color(display-p3 0.377 0.395 0.389)",
  sage12: "color(display-p3 0.107 0.129 0.118)"
}, rl = {
  sageA1: "color(display-p3 0.024 0.514 0.267 / 0.016)",
  sageA2: "color(display-p3 0.02 0.267 0.145 / 0.032)",
  sageA3: "color(display-p3 0.008 0.184 0.125 / 0.067)",
  sageA4: "color(display-p3 0.012 0.094 0.051 / 0.095)",
  sageA5: "color(display-p3 0.008 0.098 0.035 / 0.126)",
  sageA6: "color(display-p3 0.004 0.078 0.027 / 0.157)",
  sageA7: "color(display-p3 0 0.059 0.039 / 0.2)",
  sageA8: "color(display-p3 0.004 0.047 0.031 / 0.275)",
  sageA9: "color(display-p3 0.004 0.059 0.035 / 0.471)",
  sageA10: "color(display-p3 0 0.047 0.031 / 0.51)",
  sageA11: "color(display-p3 0 0.031 0.02 / 0.624)",
  sageA12: "color(display-p3 0 0.027 0.012 / 0.895)"
}, dl = {
  olive1: "#fcfdfc",
  olive2: "#f8faf8",
  olive3: "#eff1ef",
  olive4: "#e7e9e7",
  olive5: "#dfe2df",
  olive6: "#d7dad7",
  olive7: "#cccfcc",
  olive8: "#b9bcb8",
  olive9: "#898e87",
  olive10: "#7f847d",
  olive11: "#60655f",
  olive12: "#1d211c"
}, el = {
  oliveA1: "#00550003",
  oliveA2: "#00490007",
  oliveA3: "#00200010",
  oliveA4: "#00160018",
  oliveA5: "#00180020",
  oliveA6: "#00140028",
  oliveA7: "#000f0033",
  oliveA8: "#040f0047",
  oliveA9: "#050f0078",
  oliveA10: "#040e0082",
  oliveA11: "#020a00a0",
  oliveA12: "#010600e3"
}, sl = {
  olive1: "color(display-p3 0.989 0.992 0.989)",
  olive2: "color(display-p3 0.974 0.98 0.973)",
  olive3: "color(display-p3 0.939 0.945 0.937)",
  olive4: "color(display-p3 0.907 0.914 0.905)",
  olive5: "color(display-p3 0.878 0.885 0.875)",
  olive6: "color(display-p3 0.846 0.855 0.843)",
  olive7: "color(display-p3 0.803 0.812 0.8)",
  olive8: "color(display-p3 0.727 0.738 0.723)",
  olive9: "color(display-p3 0.541 0.556 0.532)",
  olive10: "color(display-p3 0.5 0.515 0.491)",
  olive11: "color(display-p3 0.38 0.395 0.374)",
  olive12: "color(display-p3 0.117 0.129 0.111)"
}, il = {
  oliveA1: "color(display-p3 0.024 0.349 0.024 / 0.012)",
  oliveA2: "color(display-p3 0.024 0.302 0.024 / 0.028)",
  oliveA3: "color(display-p3 0.008 0.129 0.008 / 0.063)",
  oliveA4: "color(display-p3 0.012 0.094 0.012 / 0.095)",
  oliveA5: "color(display-p3 0.035 0.098 0.008 / 0.126)",
  oliveA6: "color(display-p3 0.027 0.078 0.004 / 0.157)",
  oliveA7: "color(display-p3 0.02 0.059 0 / 0.2)",
  oliveA8: "color(display-p3 0.02 0.059 0.004 / 0.279)",
  oliveA9: "color(display-p3 0.02 0.051 0.004 / 0.467)",
  oliveA10: "color(display-p3 0.024 0.047 0 / 0.51)",
  oliveA11: "color(display-p3 0.012 0.039 0 / 0.628)",
  oliveA12: "color(display-p3 0.008 0.024 0 / 0.891)"
}, cl = {
  sand1: "#fdfdfc",
  sand2: "#f9f9f8",
  sand3: "#f1f0ef",
  sand4: "#e9e8e6",
  sand5: "#e2e1de",
  sand6: "#dad9d6",
  sand7: "#cfceca",
  sand8: "#bcbbb5",
  sand9: "#8d8d86",
  sand10: "#82827c",
  sand11: "#63635e",
  sand12: "#21201c"
}, yl = {
  sandA1: "#55550003",
  sandA2: "#25250007",
  sandA3: "#20100010",
  sandA4: "#1f150019",
  sandA5: "#1f180021",
  sandA6: "#19130029",
  sandA7: "#19140035",
  sandA8: "#1915014a",
  sandA9: "#0f0f0079",
  sandA10: "#0c0c0083",
  sandA11: "#080800a1",
  sandA12: "#060500e3"
}, Al = {
  sand1: "color(display-p3 0.992 0.992 0.989)",
  sand2: "color(display-p3 0.977 0.977 0.973)",
  sand3: "color(display-p3 0.943 0.942 0.936)",
  sand4: "color(display-p3 0.913 0.912 0.903)",
  sand5: "color(display-p3 0.885 0.883 0.873)",
  sand6: "color(display-p3 0.854 0.852 0.839)",
  sand7: "color(display-p3 0.813 0.81 0.794)",
  sand8: "color(display-p3 0.738 0.734 0.713)",
  sand9: "color(display-p3 0.553 0.553 0.528)",
  sand10: "color(display-p3 0.511 0.511 0.488)",
  sand11: "color(display-p3 0.388 0.388 0.37)",
  sand12: "color(display-p3 0.129 0.126 0.111)"
}, fl = {
  sandA1: "color(display-p3 0.349 0.349 0.024 / 0.012)",
  sandA2: "color(display-p3 0.161 0.161 0.024 / 0.028)",
  sandA3: "color(display-p3 0.067 0.067 0.008 / 0.063)",
  sandA4: "color(display-p3 0.129 0.129 0.012 / 0.099)",
  sandA5: "color(display-p3 0.098 0.067 0.008 / 0.126)",
  sandA6: "color(display-p3 0.102 0.075 0.004 / 0.161)",
  sandA7: "color(display-p3 0.098 0.098 0.004 / 0.208)",
  sandA8: "color(display-p3 0.086 0.075 0.004 / 0.287)",
  sandA9: "color(display-p3 0.051 0.051 0.004 / 0.471)",
  sandA10: "color(display-p3 0.047 0.047 0 / 0.514)",
  sandA11: "color(display-p3 0.031 0.031 0 / 0.632)",
  sandA12: "color(display-p3 0.024 0.02 0 / 0.891)"
}, nl = {
  tomato1: "#fffcfc",
  tomato2: "#fff8f7",
  tomato3: "#feebe7",
  tomato4: "#ffdcd3",
  tomato5: "#ffcdc2",
  tomato6: "#fdbdaf",
  tomato7: "#f5a898",
  tomato8: "#ec8e7b",
  tomato9: "#e54d2e",
  tomato10: "#dd4425",
  tomato11: "#d13415",
  tomato12: "#5c271f"
}, bl = {
  tomatoA1: "#ff000003",
  tomatoA2: "#ff200008",
  tomatoA3: "#f52b0018",
  tomatoA4: "#ff35002c",
  tomatoA5: "#ff2e003d",
  tomatoA6: "#f92d0050",
  tomatoA7: "#e7280067",
  tomatoA8: "#db250084",
  tomatoA9: "#df2600d1",
  tomatoA10: "#d72400da",
  tomatoA11: "#cd2200ea",
  tomatoA12: "#460900e0"
}, tl = {
  tomato1: "color(display-p3 0.998 0.989 0.988)",
  tomato2: "color(display-p3 0.994 0.974 0.969)",
  tomato3: "color(display-p3 0.985 0.924 0.909)",
  tomato4: "color(display-p3 0.996 0.868 0.835)",
  tomato5: "color(display-p3 0.98 0.812 0.77)",
  tomato6: "color(display-p3 0.953 0.75 0.698)",
  tomato7: "color(display-p3 0.917 0.673 0.611)",
  tomato8: "color(display-p3 0.875 0.575 0.502)",
  tomato9: "color(display-p3 0.831 0.345 0.231)",
  tomato10: "color(display-p3 0.802 0.313 0.2)",
  tomato11: "color(display-p3 0.755 0.259 0.152)",
  tomato12: "color(display-p3 0.335 0.165 0.132)"
}, gl = {
  tomatoA1: "color(display-p3 0.675 0.024 0.024 / 0.012)",
  tomatoA2: "color(display-p3 0.757 0.145 0.02 / 0.032)",
  tomatoA3: "color(display-p3 0.831 0.184 0.012 / 0.091)",
  tomatoA4: "color(display-p3 0.976 0.192 0.004 / 0.165)",
  tomatoA5: "color(display-p3 0.918 0.192 0.004 / 0.232)",
  tomatoA6: "color(display-p3 0.847 0.173 0.004 / 0.302)",
  tomatoA7: "color(display-p3 0.788 0.165 0.004 / 0.389)",
  tomatoA8: "color(display-p3 0.749 0.153 0.004 / 0.499)",
  tomatoA9: "color(display-p3 0.78 0.149 0 / 0.769)",
  tomatoA10: "color(display-p3 0.757 0.141 0 / 0.8)",
  tomatoA11: "color(display-p3 0.755 0.259 0.152)",
  tomatoA12: "color(display-p3 0.335 0.165 0.132)"
}, ml = {
  red1: "#fffcfc",
  red2: "#fff7f7",
  red3: "#feebec",
  red4: "#ffdbdc",
  red5: "#ffcdce",
  red6: "#fdbdbe",
  red7: "#f4a9aa",
  red8: "#eb8e90",
  red9: "#e5484d",
  red10: "#dc3e42",
  red11: "#ce2c31",
  red12: "#641723"
}, ul = {
  redA1: "#ff000003",
  redA2: "#ff000008",
  redA3: "#f3000d14",
  redA4: "#ff000824",
  redA5: "#ff000632",
  redA6: "#f8000442",
  redA7: "#df000356",
  redA8: "#d2000571",
  redA9: "#db0007b7",
  redA10: "#d10005c1",
  redA11: "#c40006d3",
  redA12: "#55000de8"
}, kl = {
  red1: "color(display-p3 0.998 0.989 0.988)",
  red2: "color(display-p3 0.995 0.971 0.971)",
  red3: "color(display-p3 0.985 0.925 0.925)",
  red4: "color(display-p3 0.999 0.866 0.866)",
  red5: "color(display-p3 0.984 0.812 0.811)",
  red6: "color(display-p3 0.955 0.751 0.749)",
  red7: "color(display-p3 0.915 0.675 0.672)",
  red8: "color(display-p3 0.872 0.575 0.572)",
  red9: "color(display-p3 0.83 0.329 0.324)",
  red10: "color(display-p3 0.798 0.294 0.285)",
  red11: "color(display-p3 0.744 0.234 0.222)",
  red12: "color(display-p3 0.36 0.115 0.143)"
}, vl = {
  redA1: "color(display-p3 0.675 0.024 0.024 / 0.012)",
  redA2: "color(display-p3 0.863 0.024 0.024 / 0.028)",
  redA3: "color(display-p3 0.792 0.008 0.008 / 0.075)",
  redA4: "color(display-p3 1 0.008 0.008 / 0.134)",
  redA5: "color(display-p3 0.918 0.008 0.008 / 0.189)",
  redA6: "color(display-p3 0.831 0.02 0.004 / 0.251)",
  redA7: "color(display-p3 0.741 0.016 0.004 / 0.33)",
  redA8: "color(display-p3 0.698 0.012 0.004 / 0.428)",
  redA9: "color(display-p3 0.749 0.008 0 / 0.675)",
  redA10: "color(display-p3 0.714 0.012 0 / 0.714)",
  redA11: "color(display-p3 0.744 0.234 0.222)",
  redA12: "color(display-p3 0.36 0.115 0.143)"
}, wl = {
  ruby1: "#fffcfd",
  ruby2: "#fff7f8",
  ruby3: "#feeaed",
  ruby4: "#ffdce1",
  ruby5: "#ffced6",
  ruby6: "#f8bfc8",
  ruby7: "#efacb8",
  ruby8: "#e592a3",
  ruby9: "#e54666",
  ruby10: "#dc3b5d",
  ruby11: "#ca244d",
  ruby12: "#64172b"
}, Pl = {
  rubyA1: "#ff005503",
  rubyA2: "#ff002008",
  rubyA3: "#f3002515",
  rubyA4: "#ff002523",
  rubyA5: "#ff002a31",
  rubyA6: "#e4002440",
  rubyA7: "#ce002553",
  rubyA8: "#c300286d",
  rubyA9: "#db002cb9",
  rubyA10: "#d2002cc4",
  rubyA11: "#c10030db",
  rubyA12: "#550016e8"
}, Dl = {
  ruby1: "color(display-p3 0.998 0.989 0.992)",
  ruby2: "color(display-p3 0.995 0.971 0.974)",
  ruby3: "color(display-p3 0.983 0.92 0.928)",
  ruby4: "color(display-p3 0.987 0.869 0.885)",
  ruby5: "color(display-p3 0.968 0.817 0.839)",
  ruby6: "color(display-p3 0.937 0.758 0.786)",
  ruby7: "color(display-p3 0.897 0.685 0.721)",
  ruby8: "color(display-p3 0.851 0.588 0.639)",
  ruby9: "color(display-p3 0.83 0.323 0.408)",
  ruby10: "color(display-p3 0.795 0.286 0.375)",
  ruby11: "color(display-p3 0.728 0.211 0.311)",
  ruby12: "color(display-p3 0.36 0.115 0.171)"
}, jl = {
  rubyA1: "color(display-p3 0.675 0.024 0.349 / 0.012)",
  rubyA2: "color(display-p3 0.863 0.024 0.024 / 0.028)",
  rubyA3: "color(display-p3 0.804 0.008 0.11 / 0.079)",
  rubyA4: "color(display-p3 0.91 0.008 0.125 / 0.13)",
  rubyA5: "color(display-p3 0.831 0.004 0.133 / 0.185)",
  rubyA6: "color(display-p3 0.745 0.004 0.118 / 0.244)",
  rubyA7: "color(display-p3 0.678 0.004 0.114 / 0.314)",
  rubyA8: "color(display-p3 0.639 0.004 0.125 / 0.412)",
  rubyA9: "color(display-p3 0.753 0 0.129 / 0.679)",
  rubyA10: "color(display-p3 0.714 0 0.125 / 0.714)",
  rubyA11: "color(display-p3 0.728 0.211 0.311)",
  rubyA12: "color(display-p3 0.36 0.115 0.171)"
}, zl = {
  crimson1: "#fffcfd",
  crimson2: "#fef7f9",
  crimson3: "#ffe9f0",
  crimson4: "#fedce7",
  crimson5: "#facedd",
  crimson6: "#f3bed1",
  crimson7: "#eaacc3",
  crimson8: "#e093b2",
  crimson9: "#e93d82",
  crimson10: "#df3478",
  crimson11: "#cb1d63",
  crimson12: "#621639"
}, $l = {
  crimsonA1: "#ff005503",
  crimsonA2: "#e0004008",
  crimsonA3: "#ff005216",
  crimsonA4: "#f8005123",
  crimsonA5: "#e5004f31",
  crimsonA6: "#d0004b41",
  crimsonA7: "#bf004753",
  crimsonA8: "#b6004a6c",
  crimsonA9: "#e2005bc2",
  crimsonA10: "#d70056cb",
  crimsonA11: "#c4004fe2",
  crimsonA12: "#530026e9"
}, hl = {
  crimson1: "color(display-p3 0.998 0.989 0.992)",
  crimson2: "color(display-p3 0.991 0.969 0.976)",
  crimson3: "color(display-p3 0.987 0.917 0.941)",
  crimson4: "color(display-p3 0.975 0.866 0.904)",
  crimson5: "color(display-p3 0.953 0.813 0.864)",
  crimson6: "color(display-p3 0.921 0.755 0.817)",
  crimson7: "color(display-p3 0.88 0.683 0.761)",
  crimson8: "color(display-p3 0.834 0.592 0.694)",
  crimson9: "color(display-p3 0.843 0.298 0.507)",
  crimson10: "color(display-p3 0.807 0.266 0.468)",
  crimson11: "color(display-p3 0.731 0.195 0.388)",
  crimson12: "color(display-p3 0.352 0.111 0.221)"
}, Cl = {
  crimsonA1: "color(display-p3 0.675 0.024 0.349 / 0.012)",
  crimsonA2: "color(display-p3 0.757 0.02 0.267 / 0.032)",
  crimsonA3: "color(display-p3 0.859 0.008 0.294 / 0.083)",
  crimsonA4: "color(display-p3 0.827 0.008 0.298 / 0.134)",
  crimsonA5: "color(display-p3 0.753 0.008 0.275 / 0.189)",
  crimsonA6: "color(display-p3 0.682 0.004 0.247 / 0.244)",
  crimsonA7: "color(display-p3 0.62 0.004 0.251 / 0.318)",
  crimsonA8: "color(display-p3 0.6 0.004 0.251 / 0.408)",
  crimsonA9: "color(display-p3 0.776 0 0.298 / 0.702)",
  crimsonA10: "color(display-p3 0.737 0 0.275 / 0.734)",
  crimsonA11: "color(display-p3 0.731 0.195 0.388)",
  crimsonA12: "color(display-p3 0.352 0.111 0.221)"
}, _l = {
  pink1: "#fffcfe",
  pink2: "#fef7fb",
  pink3: "#fee9f5",
  pink4: "#fbdcef",
  pink5: "#f6cee7",
  pink6: "#efbfdd",
  pink7: "#e7acd0",
  pink8: "#dd93c2",
  pink9: "#d6409f",
  pink10: "#cf3897",
  pink11: "#c2298a",
  pink12: "#651249"
}, Nl = {
  pinkA1: "#ff00aa03",
  pinkA2: "#e0008008",
  pinkA3: "#f4008c16",
  pinkA4: "#e2008b23",
  pinkA5: "#d1008331",
  pinkA6: "#c0007840",
  pinkA7: "#b6006f53",
  pinkA8: "#af006f6c",
  pinkA9: "#c8007fbf",
  pinkA10: "#c2007ac7",
  pinkA11: "#b60074d6",
  pinkA12: "#59003bed"
}, Ol = {
  pink1: "color(display-p3 0.998 0.989 0.996)",
  pink2: "color(display-p3 0.992 0.97 0.985)",
  pink3: "color(display-p3 0.981 0.917 0.96)",
  pink4: "color(display-p3 0.963 0.867 0.932)",
  pink5: "color(display-p3 0.939 0.815 0.899)",
  pink6: "color(display-p3 0.907 0.756 0.859)",
  pink7: "color(display-p3 0.869 0.683 0.81)",
  pink8: "color(display-p3 0.825 0.59 0.751)",
  pink9: "color(display-p3 0.775 0.297 0.61)",
  pink10: "color(display-p3 0.748 0.27 0.581)",
  pink11: "color(display-p3 0.698 0.219 0.528)",
  pink12: "color(display-p3 0.363 0.101 0.279)"
}, xl = {
  pinkA1: "color(display-p3 0.675 0.024 0.675 / 0.012)",
  pinkA2: "color(display-p3 0.757 0.02 0.51 / 0.032)",
  pinkA3: "color(display-p3 0.765 0.008 0.529 / 0.083)",
  pinkA4: "color(display-p3 0.737 0.008 0.506 / 0.134)",
  pinkA5: "color(display-p3 0.663 0.004 0.451 / 0.185)",
  pinkA6: "color(display-p3 0.616 0.004 0.424 / 0.244)",
  pinkA7: "color(display-p3 0.596 0.004 0.412 / 0.318)",
  pinkA8: "color(display-p3 0.573 0.004 0.404 / 0.412)",
  pinkA9: "color(display-p3 0.682 0 0.447 / 0.702)",
  pinkA10: "color(display-p3 0.655 0 0.424 / 0.73)",
  pinkA11: "color(display-p3 0.698 0.219 0.528)",
  pinkA12: "color(display-p3 0.363 0.101 0.279)"
}, Sl = {
  plum1: "#fefcff",
  plum2: "#fdf7fd",
  plum3: "#fbebfb",
  plum4: "#f7def8",
  plum5: "#f2d1f3",
  plum6: "#e9c2ec",
  plum7: "#deade3",
  plum8: "#cf91d8",
  plum9: "#ab4aba",
  plum10: "#a144af",
  plum11: "#953ea3",
  plum12: "#53195d"
}, Ml = {
  plumA1: "#aa00ff03",
  plumA2: "#c000c008",
  plumA3: "#cc00cc14",
  plumA4: "#c200c921",
  plumA5: "#b700bd2e",
  plumA6: "#a400b03d",
  plumA7: "#9900a852",
  plumA8: "#9000a56e",
  plumA9: "#89009eb5",
  plumA10: "#7f0092bb",
  plumA11: "#730086c1",
  plumA12: "#40004be6"
}, Fl = {
  plum1: "color(display-p3 0.995 0.988 0.999)",
  plum2: "color(display-p3 0.988 0.971 0.99)",
  plum3: "color(display-p3 0.973 0.923 0.98)",
  plum4: "color(display-p3 0.953 0.875 0.966)",
  plum5: "color(display-p3 0.926 0.825 0.945)",
  plum6: "color(display-p3 0.89 0.765 0.916)",
  plum7: "color(display-p3 0.84 0.686 0.877)",
  plum8: "color(display-p3 0.775 0.58 0.832)",
  plum9: "color(display-p3 0.624 0.313 0.708)",
  plum10: "color(display-p3 0.587 0.29 0.667)",
  plum11: "color(display-p3 0.543 0.263 0.619)",
  plum12: "color(display-p3 0.299 0.114 0.352)"
}, Il = {
  plumA1: "color(display-p3 0.675 0.024 1 / 0.012)",
  plumA2: "color(display-p3 0.58 0.024 0.58 / 0.028)",
  plumA3: "color(display-p3 0.655 0.008 0.753 / 0.079)",
  plumA4: "color(display-p3 0.627 0.008 0.722 / 0.126)",
  plumA5: "color(display-p3 0.58 0.004 0.69 / 0.177)",
  plumA6: "color(display-p3 0.537 0.004 0.655 / 0.236)",
  plumA7: "color(display-p3 0.49 0.004 0.616 / 0.314)",
  plumA8: "color(display-p3 0.471 0.004 0.6 / 0.42)",
  plumA9: "color(display-p3 0.451 0 0.576 / 0.687)",
  plumA10: "color(display-p3 0.42 0 0.529 / 0.71)",
  plumA11: "color(display-p3 0.543 0.263 0.619)",
  plumA12: "color(display-p3 0.299 0.114 0.352)"
}, El = {
  purple1: "#fefcfe",
  purple2: "#fbf7fe",
  purple3: "#f7edfe",
  purple4: "#f2e2fc",
  purple5: "#ead5f9",
  purple6: "#e0c4f4",
  purple7: "#d1afec",
  purple8: "#be93e4",
  purple9: "#8e4ec6",
  purple10: "#8347b9",
  purple11: "#8145b5",
  purple12: "#402060"
}, Ll = {
  purpleA1: "#aa00aa03",
  purpleA2: "#8000e008",
  purpleA3: "#8e00f112",
  purpleA4: "#8d00e51d",
  purpleA5: "#8000db2a",
  purpleA6: "#7a01d03b",
  purpleA7: "#6d00c350",
  purpleA8: "#6600c06c",
  purpleA9: "#5c00adb1",
  purpleA10: "#53009eb8",
  purpleA11: "#52009aba",
  purpleA12: "#250049df"
}, ql = {
  purple1: "color(display-p3 0.995 0.988 0.996)",
  purple2: "color(display-p3 0.983 0.971 0.993)",
  purple3: "color(display-p3 0.963 0.931 0.989)",
  purple4: "color(display-p3 0.937 0.888 0.981)",
  purple5: "color(display-p3 0.904 0.837 0.966)",
  purple6: "color(display-p3 0.86 0.774 0.942)",
  purple7: "color(display-p3 0.799 0.69 0.91)",
  purple8: "color(display-p3 0.719 0.583 0.874)",
  purple9: "color(display-p3 0.523 0.318 0.751)",
  purple10: "color(display-p3 0.483 0.289 0.7)",
  purple11: "color(display-p3 0.473 0.281 0.687)",
  purple12: "color(display-p3 0.234 0.132 0.363)"
}, Tl = {
  purpleA1: "color(display-p3 0.675 0.024 0.675 / 0.012)",
  purpleA2: "color(display-p3 0.443 0.024 0.722 / 0.028)",
  purpleA3: "color(display-p3 0.506 0.008 0.835 / 0.071)",
  purpleA4: "color(display-p3 0.451 0.004 0.831 / 0.114)",
  purpleA5: "color(display-p3 0.431 0.004 0.788 / 0.165)",
  purpleA6: "color(display-p3 0.384 0.004 0.745 / 0.228)",
  purpleA7: "color(display-p3 0.357 0.004 0.71 / 0.31)",
  purpleA8: "color(display-p3 0.322 0.004 0.702 / 0.416)",
  purpleA9: "color(display-p3 0.298 0 0.639 / 0.683)",
  purpleA10: "color(display-p3 0.271 0 0.58 / 0.71)",
  purpleA11: "color(display-p3 0.473 0.281 0.687)",
  purpleA12: "color(display-p3 0.234 0.132 0.363)"
}, Vl = {
  violet1: "#fdfcfe",
  violet2: "#faf8ff",
  violet3: "#f4f0fe",
  violet4: "#ebe4ff",
  violet5: "#e1d9ff",
  violet6: "#d4cafe",
  violet7: "#c2b5f5",
  violet8: "#aa99ec",
  violet9: "#6e56cf",
  violet10: "#654dc4",
  violet11: "#6550b9",
  violet12: "#2f265f"
}, Bl = {
  violetA1: "#5500aa03",
  violetA2: "#4900ff07",
  violetA3: "#4400ee0f",
  violetA4: "#4300ff1b",
  violetA5: "#3600ff26",
  violetA6: "#3100fb35",
  violetA7: "#2d01dd4a",
  violetA8: "#2b00d066",
  violetA9: "#2400b7a9",
  violetA10: "#2300abb2",
  violetA11: "#1f0099af",
  violetA12: "#0b0043d9"
}, Hl = {
  violet1: "color(display-p3 0.991 0.988 0.995)",
  violet2: "color(display-p3 0.978 0.974 0.998)",
  violet3: "color(display-p3 0.953 0.943 0.993)",
  violet4: "color(display-p3 0.916 0.897 1)",
  violet5: "color(display-p3 0.876 0.851 1)",
  violet6: "color(display-p3 0.825 0.793 0.981)",
  violet7: "color(display-p3 0.752 0.712 0.943)",
  violet8: "color(display-p3 0.654 0.602 0.902)",
  violet9: "color(display-p3 0.417 0.341 0.784)",
  violet10: "color(display-p3 0.381 0.306 0.741)",
  violet11: "color(display-p3 0.383 0.317 0.702)",
  violet12: "color(display-p3 0.179 0.15 0.359)"
}, Jl = {
  violetA1: "color(display-p3 0.349 0.024 0.675 / 0.012)",
  violetA2: "color(display-p3 0.161 0.024 0.863 / 0.028)",
  violetA3: "color(display-p3 0.204 0.004 0.871 / 0.059)",
  violetA4: "color(display-p3 0.196 0.004 1 / 0.102)",
  violetA5: "color(display-p3 0.165 0.008 1 / 0.15)",
  violetA6: "color(display-p3 0.153 0.004 0.906 / 0.208)",
  violetA7: "color(display-p3 0.141 0.004 0.796 / 0.287)",
  violetA8: "color(display-p3 0.133 0.004 0.753 / 0.397)",
  violetA9: "color(display-p3 0.114 0 0.675 / 0.659)",
  violetA10: "color(display-p3 0.11 0 0.627 / 0.695)",
  violetA11: "color(display-p3 0.383 0.317 0.702)",
  violetA12: "color(display-p3 0.179 0.15 0.359)"
}, Kl = {
  iris1: "#fdfdff",
  iris2: "#f8f8ff",
  iris3: "#f0f1fe",
  iris4: "#e6e7ff",
  iris5: "#dadcff",
  iris6: "#cbcdff",
  iris7: "#b8baf8",
  iris8: "#9b9ef0",
  iris9: "#5b5bd6",
  iris10: "#5151cd",
  iris11: "#5753c6",
  iris12: "#272962"
}, Ql = {
  irisA1: "#0000ff02",
  irisA2: "#0000ff07",
  irisA3: "#0011ee0f",
  irisA4: "#000bff19",
  irisA5: "#000eff25",
  irisA6: "#000aff34",
  irisA7: "#0008e647",
  irisA8: "#0008d964",
  irisA9: "#0000c0a4",
  irisA10: "#0000b6ae",
  irisA11: "#0600abac",
  irisA12: "#000246d8"
}, Rl = {
  iris1: "color(display-p3 0.992 0.992 0.999)",
  iris2: "color(display-p3 0.972 0.973 0.998)",
  iris3: "color(display-p3 0.943 0.945 0.992)",
  iris4: "color(display-p3 0.902 0.906 1)",
  iris5: "color(display-p3 0.857 0.861 1)",
  iris6: "color(display-p3 0.799 0.805 0.987)",
  iris7: "color(display-p3 0.721 0.727 0.955)",
  iris8: "color(display-p3 0.61 0.619 0.918)",
  iris9: "color(display-p3 0.357 0.357 0.81)",
  iris10: "color(display-p3 0.318 0.318 0.774)",
  iris11: "color(display-p3 0.337 0.326 0.748)",
  iris12: "color(display-p3 0.154 0.161 0.371)"
}, Ul = {
  irisA1: "color(display-p3 0.02 0.02 1 / 0.008)",
  irisA2: "color(display-p3 0.024 0.024 0.863 / 0.028)",
  irisA3: "color(display-p3 0.004 0.071 0.871 / 0.059)",
  irisA4: "color(display-p3 0.012 0.051 1 / 0.099)",
  irisA5: "color(display-p3 0.008 0.035 1 / 0.142)",
  irisA6: "color(display-p3 0 0.02 0.941 / 0.2)",
  irisA7: "color(display-p3 0.004 0.02 0.847 / 0.279)",
  irisA8: "color(display-p3 0.004 0.024 0.788 / 0.389)",
  irisA9: "color(display-p3 0 0 0.706 / 0.644)",
  irisA10: "color(display-p3 0 0 0.667 / 0.683)",
  irisA11: "color(display-p3 0.337 0.326 0.748)",
  irisA12: "color(display-p3 0.154 0.161 0.371)"
}, Wl = {
  indigo1: "#fdfdfe",
  indigo2: "#f7f9ff",
  indigo3: "#edf2fe",
  indigo4: "#e1e9ff",
  indigo5: "#d2deff",
  indigo6: "#c1d0ff",
  indigo7: "#abbdf9",
  indigo8: "#8da4ef",
  indigo9: "#3e63dd",
  indigo10: "#3358d4",
  indigo11: "#3a5bc7",
  indigo12: "#1f2d5c"
}, Xl = {
  indigoA1: "#00008002",
  indigoA2: "#0040ff08",
  indigoA3: "#0047f112",
  indigoA4: "#0044ff1e",
  indigoA5: "#0044ff2d",
  indigoA6: "#003eff3e",
  indigoA7: "#0037ed54",
  indigoA8: "#0034dc72",
  indigoA9: "#0031d2c1",
  indigoA10: "#002ec9cc",
  indigoA11: "#002bb7c5",
  indigoA12: "#001046e0"
}, Yl = {
  indigo1: "color(display-p3 0.992 0.992 0.996)",
  indigo2: "color(display-p3 0.971 0.977 0.998)",
  indigo3: "color(display-p3 0.933 0.948 0.992)",
  indigo4: "color(display-p3 0.885 0.914 1)",
  indigo5: "color(display-p3 0.831 0.87 1)",
  indigo6: "color(display-p3 0.767 0.814 0.995)",
  indigo7: "color(display-p3 0.685 0.74 0.957)",
  indigo8: "color(display-p3 0.569 0.639 0.916)",
  indigo9: "color(display-p3 0.276 0.384 0.837)",
  indigo10: "color(display-p3 0.234 0.343 0.801)",
  indigo11: "color(display-p3 0.256 0.354 0.755)",
  indigo12: "color(display-p3 0.133 0.175 0.348)"
}, Zl = {
  indigoA1: "color(display-p3 0.02 0.02 0.51 / 0.008)",
  indigoA2: "color(display-p3 0.024 0.161 0.863 / 0.028)",
  indigoA3: "color(display-p3 0.008 0.239 0.886 / 0.067)",
  indigoA4: "color(display-p3 0.004 0.247 1 / 0.114)",
  indigoA5: "color(display-p3 0.004 0.235 1 / 0.169)",
  indigoA6: "color(display-p3 0.004 0.208 0.984 / 0.232)",
  indigoA7: "color(display-p3 0.004 0.176 0.863 / 0.314)",
  indigoA8: "color(display-p3 0.004 0.165 0.812 / 0.432)",
  indigoA9: "color(display-p3 0 0.153 0.773 / 0.726)",
  indigoA10: "color(display-p3 0 0.137 0.737 / 0.765)",
  indigoA11: "color(display-p3 0.256 0.354 0.755)",
  indigoA12: "color(display-p3 0.133 0.175 0.348)"
}, Gl = {
  blue1: "#fbfdff",
  blue2: "#f4faff",
  blue3: "#e6f4fe",
  blue4: "#d5efff",
  blue5: "#c2e5ff",
  blue6: "#acd8fc",
  blue7: "#8ec8f6",
  blue8: "#5eb1ef",
  blue9: "#0090ff",
  blue10: "#0588f0",
  blue11: "#0d74ce",
  blue12: "#113264"
}, o3 = {
  blueA1: "#0080ff04",
  blueA2: "#008cff0b",
  blueA3: "#008ff519",
  blueA4: "#009eff2a",
  blueA5: "#0093ff3d",
  blueA6: "#0088f653",
  blueA7: "#0083eb71",
  blueA8: "#0084e6a1",
  blueA9: "#0090ff",
  blueA10: "#0086f0fa",
  blueA11: "#006dcbf2",
  blueA12: "#002359ee"
}, l3 = {
  blue1: "color(display-p3 0.986 0.992 0.999)",
  blue2: "color(display-p3 0.96 0.979 0.998)",
  blue3: "color(display-p3 0.912 0.956 0.991)",
  blue4: "color(display-p3 0.853 0.932 1)",
  blue5: "color(display-p3 0.788 0.894 0.998)",
  blue6: "color(display-p3 0.709 0.843 0.976)",
  blue7: "color(display-p3 0.606 0.777 0.947)",
  blue8: "color(display-p3 0.451 0.688 0.917)",
  blue9: "color(display-p3 0.247 0.556 0.969)",
  blue10: "color(display-p3 0.234 0.523 0.912)",
  blue11: "color(display-p3 0.15 0.44 0.84)",
  blue12: "color(display-p3 0.102 0.193 0.379)"
}, a3 = {
  blueA1: "color(display-p3 0.024 0.514 1 / 0.016)",
  blueA2: "color(display-p3 0.024 0.514 0.906 / 0.04)",
  blueA3: "color(display-p3 0.012 0.506 0.914 / 0.087)",
  blueA4: "color(display-p3 0.008 0.545 1 / 0.146)",
  blueA5: "color(display-p3 0.004 0.502 0.984 / 0.212)",
  blueA6: "color(display-p3 0.004 0.463 0.922 / 0.291)",
  blueA7: "color(display-p3 0.004 0.431 0.863 / 0.393)",
  blueA8: "color(display-p3 0 0.427 0.851 / 0.55)",
  blueA9: "color(display-p3 0 0.412 0.961 / 0.753)",
  blueA10: "color(display-p3 0 0.376 0.886 / 0.765)",
  blueA11: "color(display-p3 0.15 0.44 0.84)",
  blueA12: "color(display-p3 0.102 0.193 0.379)"
}, p3 = {
  cyan1: "#fafdfe",
  cyan2: "#f2fafb",
  cyan3: "#def7f9",
  cyan4: "#caf1f6",
  cyan5: "#b5e9f0",
  cyan6: "#9ddde7",
  cyan7: "#7dcedc",
  cyan8: "#3db9cf",
  cyan9: "#00a2c7",
  cyan10: "#0797b9",
  cyan11: "#107d98",
  cyan12: "#0d3c48"
}, r3 = {
  cyanA1: "#0099cc05",
  cyanA2: "#009db10d",
  cyanA3: "#00c2d121",
  cyanA4: "#00bcd435",
  cyanA5: "#01b4cc4a",
  cyanA6: "#00a7c162",
  cyanA7: "#009fbb82",
  cyanA8: "#00a3c0c2",
  cyanA9: "#00a2c7",
  cyanA10: "#0094b7f8",
  cyanA11: "#007491ef",
  cyanA12: "#00323ef2"
}, d3 = {
  cyan1: "color(display-p3 0.982 0.992 0.996)",
  cyan2: "color(display-p3 0.955 0.981 0.984)",
  cyan3: "color(display-p3 0.888 0.965 0.975)",
  cyan4: "color(display-p3 0.821 0.941 0.959)",
  cyan5: "color(display-p3 0.751 0.907 0.935)",
  cyan6: "color(display-p3 0.671 0.862 0.9)",
  cyan7: "color(display-p3 0.564 0.8 0.854)",
  cyan8: "color(display-p3 0.388 0.715 0.798)",
  cyan9: "color(display-p3 0.282 0.627 0.765)",
  cyan10: "color(display-p3 0.264 0.583 0.71)",
  cyan11: "color(display-p3 0.08 0.48 0.63)",
  cyan12: "color(display-p3 0.108 0.232 0.277)"
}, e3 = {
  cyanA1: "color(display-p3 0.02 0.608 0.804 / 0.02)",
  cyanA2: "color(display-p3 0.02 0.557 0.647 / 0.044)",
  cyanA3: "color(display-p3 0.004 0.694 0.796 / 0.114)",
  cyanA4: "color(display-p3 0.004 0.678 0.784 / 0.181)",
  cyanA5: "color(display-p3 0.004 0.624 0.733 / 0.248)",
  cyanA6: "color(display-p3 0.004 0.584 0.706 / 0.33)",
  cyanA7: "color(display-p3 0.004 0.541 0.667 / 0.436)",
  cyanA8: "color(display-p3 0 0.533 0.667 / 0.612)",
  cyanA9: "color(display-p3 0 0.482 0.675 / 0.718)",
  cyanA10: "color(display-p3 0 0.435 0.608 / 0.738)",
  cyanA11: "color(display-p3 0.08 0.48 0.63)",
  cyanA12: "color(display-p3 0.108 0.232 0.277)"
}, s3 = {
  teal1: "#fafefd",
  teal2: "#f3fbf9",
  teal3: "#e0f8f3",
  teal4: "#ccf3ea",
  teal5: "#b8eae0",
  teal6: "#a1ded2",
  teal7: "#83cdc1",
  teal8: "#53b9ab",
  teal9: "#12a594",
  teal10: "#0d9b8a",
  teal11: "#008573",
  teal12: "#0d3d38"
}, i3 = {
  tealA1: "#00cc9905",
  tealA2: "#00aa800c",
  tealA3: "#00c69d1f",
  tealA4: "#00c39633",
  tealA5: "#00b49047",
  tealA6: "#00a6855e",
  tealA7: "#0099807c",
  tealA8: "#009783ac",
  tealA9: "#009e8ced",
  tealA10: "#009684f2",
  tealA11: "#008573",
  tealA12: "#00332df2"
}, c3 = {
  teal1: "color(display-p3 0.983 0.996 0.992)",
  teal2: "color(display-p3 0.958 0.983 0.976)",
  teal3: "color(display-p3 0.895 0.971 0.952)",
  teal4: "color(display-p3 0.831 0.949 0.92)",
  teal5: "color(display-p3 0.761 0.914 0.878)",
  teal6: "color(display-p3 0.682 0.864 0.825)",
  teal7: "color(display-p3 0.581 0.798 0.756)",
  teal8: "color(display-p3 0.433 0.716 0.671)",
  teal9: "color(display-p3 0.297 0.637 0.581)",
  teal10: "color(display-p3 0.275 0.599 0.542)",
  teal11: "color(display-p3 0.08 0.5 0.43)",
  teal12: "color(display-p3 0.11 0.235 0.219)"
}, y3 = {
  tealA1: "color(display-p3 0.024 0.757 0.514 / 0.016)",
  tealA2: "color(display-p3 0.02 0.647 0.467 / 0.044)",
  tealA3: "color(display-p3 0.004 0.741 0.557 / 0.106)",
  tealA4: "color(display-p3 0.004 0.702 0.537 / 0.169)",
  tealA5: "color(display-p3 0.004 0.643 0.494 / 0.24)",
  tealA6: "color(display-p3 0.004 0.569 0.447 / 0.318)",
  tealA7: "color(display-p3 0.004 0.518 0.424 / 0.42)",
  tealA8: "color(display-p3 0 0.506 0.424 / 0.569)",
  tealA9: "color(display-p3 0 0.482 0.404 / 0.702)",
  tealA10: "color(display-p3 0 0.451 0.369 / 0.726)",
  tealA11: "color(display-p3 0.08 0.5 0.43)",
  tealA12: "color(display-p3 0.11 0.235 0.219)"
}, A3 = {
  jade1: "#fbfefd",
  jade2: "#f4fbf7",
  jade3: "#e6f7ed",
  jade4: "#d6f1e3",
  jade5: "#c3e9d7",
  jade6: "#acdec8",
  jade7: "#8bceb6",
  jade8: "#56ba9f",
  jade9: "#29a383",
  jade10: "#26997b",
  jade11: "#208368",
  jade12: "#1d3b31"
}, f3 = {
  jadeA1: "#00c08004",
  jadeA2: "#00a3460b",
  jadeA3: "#00ae4819",
  jadeA4: "#00a85129",
  jadeA5: "#00a2553c",
  jadeA6: "#009a5753",
  jadeA7: "#00945f74",
  jadeA8: "#00976ea9",
  jadeA9: "#00916bd6",
  jadeA10: "#008764d9",
  jadeA11: "#007152df",
  jadeA12: "#002217e2"
}, n3 = {
  jade1: "color(display-p3 0.986 0.996 0.992)",
  jade2: "color(display-p3 0.962 0.983 0.969)",
  jade3: "color(display-p3 0.912 0.965 0.932)",
  jade4: "color(display-p3 0.858 0.941 0.893)",
  jade5: "color(display-p3 0.795 0.909 0.847)",
  jade6: "color(display-p3 0.715 0.864 0.791)",
  jade7: "color(display-p3 0.603 0.802 0.718)",
  jade8: "color(display-p3 0.44 0.72 0.629)",
  jade9: "color(display-p3 0.319 0.63 0.521)",
  jade10: "color(display-p3 0.299 0.592 0.488)",
  jade11: "color(display-p3 0.15 0.5 0.37)",
  jade12: "color(display-p3 0.142 0.229 0.194)"
}, b3 = {
  jadeA1: "color(display-p3 0.024 0.757 0.514 / 0.016)",
  jadeA2: "color(display-p3 0.024 0.612 0.22 / 0.04)",
  jadeA3: "color(display-p3 0.012 0.596 0.235 / 0.087)",
  jadeA4: "color(display-p3 0.008 0.588 0.255 / 0.142)",
  jadeA5: "color(display-p3 0.004 0.561 0.251 / 0.204)",
  jadeA6: "color(display-p3 0.004 0.525 0.278 / 0.287)",
  jadeA7: "color(display-p3 0.004 0.506 0.29 / 0.397)",
  jadeA8: "color(display-p3 0 0.506 0.337 / 0.561)",
  jadeA9: "color(display-p3 0 0.459 0.298 / 0.683)",
  jadeA10: "color(display-p3 0 0.42 0.271 / 0.702)",
  jadeA11: "color(display-p3 0.15 0.5 0.37)",
  jadeA12: "color(display-p3 0.142 0.229 0.194)"
}, t3 = {
  green1: "#fbfefc",
  green2: "#f4fbf6",
  green3: "#e6f6eb",
  green4: "#d6f1df",
  green5: "#c4e8d1",
  green6: "#adddc0",
  green7: "#8eceaa",
  green8: "#5bb98b",
  green9: "#30a46c",
  green10: "#2b9a66",
  green11: "#218358",
  green12: "#193b2d"
}, g3 = {
  greenA1: "#00c04004",
  greenA2: "#00a32f0b",
  greenA3: "#00a43319",
  greenA4: "#00a83829",
  greenA5: "#019c393b",
  greenA6: "#00963c52",
  greenA7: "#00914071",
  greenA8: "#00924ba4",
  greenA9: "#008f4acf",
  greenA10: "#008647d4",
  greenA11: "#00713fde",
  greenA12: "#002616e6"
}, m3 = {
  green1: "color(display-p3 0.986 0.996 0.989)",
  green2: "color(display-p3 0.963 0.983 0.967)",
  green3: "color(display-p3 0.913 0.964 0.925)",
  green4: "color(display-p3 0.859 0.94 0.879)",
  green5: "color(display-p3 0.796 0.907 0.826)",
  green6: "color(display-p3 0.718 0.863 0.761)",
  green7: "color(display-p3 0.61 0.801 0.675)",
  green8: "color(display-p3 0.451 0.715 0.559)",
  green9: "color(display-p3 0.332 0.634 0.442)",
  green10: "color(display-p3 0.308 0.595 0.417)",
  green11: "color(display-p3 0.19 0.5 0.32)",
  green12: "color(display-p3 0.132 0.228 0.18)"
}, u3 = {
  greenA1: "color(display-p3 0.024 0.757 0.267 / 0.016)",
  greenA2: "color(display-p3 0.024 0.565 0.129 / 0.036)",
  greenA3: "color(display-p3 0.012 0.596 0.145 / 0.087)",
  greenA4: "color(display-p3 0.008 0.588 0.145 / 0.142)",
  greenA5: "color(display-p3 0.004 0.541 0.157 / 0.204)",
  greenA6: "color(display-p3 0.004 0.518 0.157 / 0.283)",
  greenA7: "color(display-p3 0.004 0.486 0.165 / 0.389)",
  greenA8: "color(display-p3 0 0.478 0.2 / 0.55)",
  greenA9: "color(display-p3 0 0.455 0.165 / 0.667)",
  greenA10: "color(display-p3 0 0.416 0.153 / 0.691)",
  greenA11: "color(display-p3 0.19 0.5 0.32)",
  greenA12: "color(display-p3 0.132 0.228 0.18)"
}, k3 = {
  grass1: "#fbfefb",
  grass2: "#f5fbf5",
  grass3: "#e9f6e9",
  grass4: "#daf1db",
  grass5: "#c9e8ca",
  grass6: "#b2ddb5",
  grass7: "#94ce9a",
  grass8: "#65ba74",
  grass9: "#46a758",
  grass10: "#3e9b4f",
  grass11: "#2a7e3b",
  grass12: "#203c25"
}, v3 = {
  grassA1: "#00c00004",
  grassA2: "#0099000a",
  grassA3: "#00970016",
  grassA4: "#009f0725",
  grassA5: "#00930536",
  grassA6: "#008f0a4d",
  grassA7: "#018b0f6b",
  grassA8: "#008d199a",
  grassA9: "#008619b9",
  grassA10: "#007b17c1",
  grassA11: "#006514d5",
  grassA12: "#002006df"
}, w3 = {
  grass1: "color(display-p3 0.986 0.996 0.985)",
  grass2: "color(display-p3 0.966 0.983 0.964)",
  grass3: "color(display-p3 0.923 0.965 0.917)",
  grass4: "color(display-p3 0.872 0.94 0.865)",
  grass5: "color(display-p3 0.811 0.908 0.802)",
  grass6: "color(display-p3 0.733 0.864 0.724)",
  grass7: "color(display-p3 0.628 0.803 0.622)",
  grass8: "color(display-p3 0.477 0.72 0.482)",
  grass9: "color(display-p3 0.38 0.647 0.378)",
  grass10: "color(display-p3 0.344 0.598 0.342)",
  grass11: "color(display-p3 0.263 0.488 0.261)",
  grass12: "color(display-p3 0.151 0.233 0.153)"
}, P3 = {
  grassA1: "color(display-p3 0.024 0.757 0.024 / 0.016)",
  grassA2: "color(display-p3 0.024 0.565 0.024 / 0.036)",
  grassA3: "color(display-p3 0.059 0.576 0.008 / 0.083)",
  grassA4: "color(display-p3 0.035 0.565 0.008 / 0.134)",
  grassA5: "color(display-p3 0.047 0.545 0.008 / 0.197)",
  grassA6: "color(display-p3 0.031 0.502 0.004 / 0.275)",
  grassA7: "color(display-p3 0.012 0.482 0.004 / 0.377)",
  grassA8: "color(display-p3 0 0.467 0.008 / 0.522)",
  grassA9: "color(display-p3 0.008 0.435 0 / 0.624)",
  grassA10: "color(display-p3 0.008 0.388 0 / 0.659)",
  grassA11: "color(display-p3 0.263 0.488 0.261)",
  grassA12: "color(display-p3 0.151 0.233 0.153)"
}, D3 = {
  brown1: "#fefdfc",
  brown2: "#fcf9f6",
  brown3: "#f6eee7",
  brown4: "#f0e4d9",
  brown5: "#ebdaca",
  brown6: "#e4cdb7",
  brown7: "#dcbc9f",
  brown8: "#cea37e",
  brown9: "#ad7f58",
  brown10: "#a07553",
  brown11: "#815e46",
  brown12: "#3e332e"
}, j3 = {
  brownA1: "#aa550003",
  brownA2: "#aa550009",
  brownA3: "#a04b0018",
  brownA4: "#9b4a0026",
  brownA5: "#9f4d0035",
  brownA6: "#a04e0048",
  brownA7: "#a34e0060",
  brownA8: "#9f4a0081",
  brownA9: "#823c00a7",
  brownA10: "#723300ac",
  brownA11: "#522100b9",
  brownA12: "#140600d1"
}, z3 = {
  brown1: "color(display-p3 0.995 0.992 0.989)",
  brown2: "color(display-p3 0.987 0.976 0.964)",
  brown3: "color(display-p3 0.959 0.936 0.909)",
  brown4: "color(display-p3 0.934 0.897 0.855)",
  brown5: "color(display-p3 0.909 0.856 0.798)",
  brown6: "color(display-p3 0.88 0.808 0.73)",
  brown7: "color(display-p3 0.841 0.742 0.639)",
  brown8: "color(display-p3 0.782 0.647 0.514)",
  brown9: "color(display-p3 0.651 0.505 0.368)",
  brown10: "color(display-p3 0.601 0.465 0.344)",
  brown11: "color(display-p3 0.485 0.374 0.288)",
  brown12: "color(display-p3 0.236 0.202 0.183)"
}, $3 = {
  brownA1: "color(display-p3 0.675 0.349 0.024 / 0.012)",
  brownA2: "color(display-p3 0.675 0.349 0.024 / 0.036)",
  brownA3: "color(display-p3 0.573 0.314 0.012 / 0.091)",
  brownA4: "color(display-p3 0.545 0.302 0.008 / 0.146)",
  brownA5: "color(display-p3 0.561 0.29 0.004 / 0.204)",
  brownA6: "color(display-p3 0.553 0.294 0.004 / 0.271)",
  brownA7: "color(display-p3 0.557 0.286 0.004 / 0.361)",
  brownA8: "color(display-p3 0.549 0.275 0.004 / 0.487)",
  brownA9: "color(display-p3 0.447 0.22 0 / 0.632)",
  brownA10: "color(display-p3 0.388 0.188 0 / 0.655)",
  brownA11: "color(display-p3 0.485 0.374 0.288)",
  brownA12: "color(display-p3 0.236 0.202 0.183)"
}, h3 = {
  bronze1: "#fdfcfc",
  bronze2: "#fdf7f5",
  bronze3: "#f6edea",
  bronze4: "#efe4df",
  bronze5: "#e7d9d3",
  bronze6: "#dfcdc5",
  bronze7: "#d3bcb3",
  bronze8: "#c2a499",
  bronze9: "#a18072",
  bronze10: "#957468",
  bronze11: "#7d5e54",
  bronze12: "#43302b"
}, C3 = {
  bronzeA1: "#55000003",
  bronzeA2: "#cc33000a",
  bronzeA3: "#92250015",
  bronzeA4: "#80280020",
  bronzeA5: "#7423002c",
  bronzeA6: "#7324003a",
  bronzeA7: "#6c1f004c",
  bronzeA8: "#671c0066",
  bronzeA9: "#551a008d",
  bronzeA10: "#4c150097",
  bronzeA11: "#3d0f00ab",
  bronzeA12: "#1d0600d4"
}, _3 = {
  bronze1: "color(display-p3 0.991 0.988 0.988)",
  bronze2: "color(display-p3 0.989 0.97 0.961)",
  bronze3: "color(display-p3 0.958 0.932 0.919)",
  bronze4: "color(display-p3 0.929 0.894 0.877)",
  bronze5: "color(display-p3 0.898 0.853 0.832)",
  bronze6: "color(display-p3 0.861 0.805 0.778)",
  bronze7: "color(display-p3 0.812 0.739 0.706)",
  bronze8: "color(display-p3 0.741 0.647 0.606)",
  bronze9: "color(display-p3 0.611 0.507 0.455)",
  bronze10: "color(display-p3 0.563 0.461 0.414)",
  bronze11: "color(display-p3 0.471 0.373 0.336)",
  bronze12: "color(display-p3 0.251 0.191 0.172)"
}, N3 = {
  bronzeA1: "color(display-p3 0.349 0.024 0.024 / 0.012)",
  bronzeA2: "color(display-p3 0.71 0.22 0.024 / 0.04)",
  bronzeA3: "color(display-p3 0.482 0.2 0.008 / 0.083)",
  bronzeA4: "color(display-p3 0.424 0.133 0.004 / 0.122)",
  bronzeA5: "color(display-p3 0.4 0.145 0.004 / 0.169)",
  bronzeA6: "color(display-p3 0.388 0.125 0.004 / 0.224)",
  bronzeA7: "color(display-p3 0.365 0.11 0.004 / 0.295)",
  bronzeA8: "color(display-p3 0.341 0.102 0.004 / 0.393)",
  bronzeA9: "color(display-p3 0.29 0.094 0 / 0.546)",
  bronzeA10: "color(display-p3 0.255 0.082 0 / 0.585)",
  bronzeA11: "color(display-p3 0.471 0.373 0.336)",
  bronzeA12: "color(display-p3 0.251 0.191 0.172)"
}, O3 = {
  gold1: "#fdfdfc",
  gold2: "#faf9f2",
  gold3: "#f2f0e7",
  gold4: "#eae6db",
  gold5: "#e1dccf",
  gold6: "#d8d0bf",
  gold7: "#cbc0aa",
  gold8: "#b9a88d",
  gold9: "#978365",
  gold10: "#8c7a5e",
  gold11: "#71624b",
  gold12: "#3b352b"
}, x3 = {
  goldA1: "#55550003",
  goldA2: "#9d8a000d",
  goldA3: "#75600018",
  goldA4: "#6b4e0024",
  goldA5: "#60460030",
  goldA6: "#64440040",
  goldA7: "#63420055",
  goldA8: "#633d0072",
  goldA9: "#5332009a",
  goldA10: "#492d00a1",
  goldA11: "#362100b4",
  goldA12: "#130c00d4"
}, S3 = {
  gold1: "color(display-p3 0.992 0.992 0.989)",
  gold2: "color(display-p3 0.98 0.976 0.953)",
  gold3: "color(display-p3 0.947 0.94 0.909)",
  gold4: "color(display-p3 0.914 0.904 0.865)",
  gold5: "color(display-p3 0.88 0.865 0.816)",
  gold6: "color(display-p3 0.84 0.818 0.756)",
  gold7: "color(display-p3 0.788 0.753 0.677)",
  gold8: "color(display-p3 0.715 0.66 0.565)",
  gold9: "color(display-p3 0.579 0.517 0.41)",
  gold10: "color(display-p3 0.538 0.479 0.38)",
  gold11: "color(display-p3 0.433 0.386 0.305)",
  gold12: "color(display-p3 0.227 0.209 0.173)"
}, M3 = {
  goldA1: "color(display-p3 0.349 0.349 0.024 / 0.012)",
  goldA2: "color(display-p3 0.592 0.514 0.024 / 0.048)",
  goldA3: "color(display-p3 0.4 0.357 0.012 / 0.091)",
  goldA4: "color(display-p3 0.357 0.298 0.008 / 0.134)",
  goldA5: "color(display-p3 0.345 0.282 0.004 / 0.185)",
  goldA6: "color(display-p3 0.341 0.263 0.004 / 0.244)",
  goldA7: "color(display-p3 0.345 0.235 0.004 / 0.322)",
  goldA8: "color(display-p3 0.345 0.22 0.004 / 0.436)",
  goldA9: "color(display-p3 0.286 0.18 0 / 0.589)",
  goldA10: "color(display-p3 0.255 0.161 0 / 0.62)",
  goldA11: "color(display-p3 0.433 0.386 0.305)",
  goldA12: "color(display-p3 0.227 0.209 0.173)"
}, F3 = {
  sky1: "#f9feff",
  sky2: "#f1fafd",
  sky3: "#e1f6fd",
  sky4: "#d1f0fa",
  sky5: "#bee7f5",
  sky6: "#a9daed",
  sky7: "#8dcae3",
  sky8: "#60b3d7",
  sky9: "#7ce2fe",
  sky10: "#74daf8",
  sky11: "#00749e",
  sky12: "#1d3e56"
}, I3 = {
  skyA1: "#00d5ff06",
  skyA2: "#00a4db0e",
  skyA3: "#00b3ee1e",
  skyA4: "#00ace42e",
  skyA5: "#00a1d841",
  skyA6: "#0092ca56",
  skyA7: "#0089c172",
  skyA8: "#0085bf9f",
  skyA9: "#00c7fe83",
  skyA10: "#00bcf38b",
  skyA11: "#00749e",
  skyA12: "#002540e2"
}, E3 = {
  sky1: "color(display-p3 0.98 0.995 0.999)",
  sky2: "color(display-p3 0.953 0.98 0.99)",
  sky3: "color(display-p3 0.899 0.963 0.989)",
  sky4: "color(display-p3 0.842 0.937 0.977)",
  sky5: "color(display-p3 0.777 0.9 0.954)",
  sky6: "color(display-p3 0.701 0.851 0.921)",
  sky7: "color(display-p3 0.604 0.785 0.879)",
  sky8: "color(display-p3 0.457 0.696 0.829)",
  sky9: "color(display-p3 0.585 0.877 0.983)",
  sky10: "color(display-p3 0.555 0.845 0.959)",
  sky11: "color(display-p3 0.193 0.448 0.605)",
  sky12: "color(display-p3 0.145 0.241 0.329)"
}, L3 = {
  skyA1: "color(display-p3 0.02 0.804 1 / 0.02)",
  skyA2: "color(display-p3 0.024 0.592 0.757 / 0.048)",
  skyA3: "color(display-p3 0.004 0.655 0.886 / 0.102)",
  skyA4: "color(display-p3 0.004 0.604 0.851 / 0.157)",
  skyA5: "color(display-p3 0.004 0.565 0.792 / 0.224)",
  skyA6: "color(display-p3 0.004 0.502 0.737 / 0.299)",
  skyA7: "color(display-p3 0.004 0.459 0.694 / 0.397)",
  skyA8: "color(display-p3 0 0.435 0.682 / 0.542)",
  skyA9: "color(display-p3 0.004 0.71 0.965 / 0.416)",
  skyA10: "color(display-p3 0.004 0.647 0.914 / 0.444)",
  skyA11: "color(display-p3 0.193 0.448 0.605)",
  skyA12: "color(display-p3 0.145 0.241 0.329)"
}, q3 = {
  mint1: "#f9fefd",
  mint2: "#f2fbf9",
  mint3: "#ddf9f2",
  mint4: "#c8f4e9",
  mint5: "#b3ecde",
  mint6: "#9ce0d0",
  mint7: "#7ecfbd",
  mint8: "#4cbba5",
  mint9: "#86ead4",
  mint10: "#7de0cb",
  mint11: "#027864",
  mint12: "#16433c"
}, T3 = {
  mintA1: "#00d5aa06",
  mintA2: "#00b18a0d",
  mintA3: "#00d29e22",
  mintA4: "#00cc9937",
  mintA5: "#00c0914c",
  mintA6: "#00b08663",
  mintA7: "#00a17d81",
  mintA8: "#009e7fb3",
  mintA9: "#00d3a579",
  mintA10: "#00c39982",
  mintA11: "#007763fd",
  mintA12: "#00312ae9"
}, V3 = {
  mint1: "color(display-p3 0.98 0.995 0.992)",
  mint2: "color(display-p3 0.957 0.985 0.977)",
  mint3: "color(display-p3 0.888 0.972 0.95)",
  mint4: "color(display-p3 0.819 0.951 0.916)",
  mint5: "color(display-p3 0.747 0.918 0.873)",
  mint6: "color(display-p3 0.668 0.87 0.818)",
  mint7: "color(display-p3 0.567 0.805 0.744)",
  mint8: "color(display-p3 0.42 0.724 0.649)",
  mint9: "color(display-p3 0.62 0.908 0.834)",
  mint10: "color(display-p3 0.585 0.871 0.797)",
  mint11: "color(display-p3 0.203 0.463 0.397)",
  mint12: "color(display-p3 0.136 0.259 0.236)"
}, B3 = {
  mintA1: "color(display-p3 0.02 0.804 0.608 / 0.02)",
  mintA2: "color(display-p3 0.02 0.647 0.467 / 0.044)",
  mintA3: "color(display-p3 0.004 0.761 0.553 / 0.114)",
  mintA4: "color(display-p3 0.004 0.741 0.545 / 0.181)",
  mintA5: "color(display-p3 0.004 0.678 0.51 / 0.255)",
  mintA6: "color(display-p3 0.004 0.616 0.463 / 0.334)",
  mintA7: "color(display-p3 0.004 0.549 0.412 / 0.432)",
  mintA8: "color(display-p3 0 0.529 0.392 / 0.581)",
  mintA9: "color(display-p3 0.004 0.765 0.569 / 0.381)",
  mintA10: "color(display-p3 0.004 0.69 0.51 / 0.416)",
  mintA11: "color(display-p3 0.203 0.463 0.397)",
  mintA12: "color(display-p3 0.136 0.259 0.236)"
}, H3 = {
  lime1: "#fcfdfa",
  lime2: "#f8faf3",
  lime3: "#eef6d6",
  lime4: "#e2f0bd",
  lime5: "#d3e7a6",
  lime6: "#c2da91",
  lime7: "#abc978",
  lime8: "#8db654",
  lime9: "#bdee63",
  lime10: "#b0e64c",
  lime11: "#5c7c2f",
  lime12: "#37401c"
}, J3 = {
  limeA1: "#66990005",
  limeA2: "#6b95000c",
  limeA3: "#96c80029",
  limeA4: "#8fc60042",
  limeA5: "#81bb0059",
  limeA6: "#72aa006e",
  limeA7: "#61990087",
  limeA8: "#559200ab",
  limeA9: "#93e4009c",
  limeA10: "#8fdc00b3",
  limeA11: "#375f00d0",
  limeA12: "#1e2900e3"
}, K3 = {
  lime1: "color(display-p3 0.989 0.992 0.981)",
  lime2: "color(display-p3 0.975 0.98 0.954)",
  lime3: "color(display-p3 0.939 0.965 0.851)",
  lime4: "color(display-p3 0.896 0.94 0.76)",
  lime5: "color(display-p3 0.843 0.903 0.678)",
  lime6: "color(display-p3 0.778 0.852 0.599)",
  lime7: "color(display-p3 0.694 0.784 0.508)",
  lime8: "color(display-p3 0.585 0.707 0.378)",
  lime9: "color(display-p3 0.78 0.928 0.466)",
  lime10: "color(display-p3 0.734 0.896 0.397)",
  lime11: "color(display-p3 0.386 0.482 0.227)",
  lime12: "color(display-p3 0.222 0.25 0.128)"
}, Q3 = {
  limeA1: "color(display-p3 0.412 0.608 0.02 / 0.02)",
  limeA2: "color(display-p3 0.514 0.592 0.024 / 0.048)",
  limeA3: "color(display-p3 0.584 0.765 0.008 / 0.15)",
  limeA4: "color(display-p3 0.561 0.757 0.004 / 0.24)",
  limeA5: "color(display-p3 0.514 0.698 0.004 / 0.322)",
  limeA6: "color(display-p3 0.443 0.627 0 / 0.4)",
  limeA7: "color(display-p3 0.376 0.561 0.004 / 0.491)",
  limeA8: "color(display-p3 0.333 0.529 0 / 0.624)",
  limeA9: "color(display-p3 0.588 0.867 0 / 0.534)",
  limeA10: "color(display-p3 0.561 0.827 0 / 0.604)",
  limeA11: "color(display-p3 0.386 0.482 0.227)",
  limeA12: "color(display-p3 0.222 0.25 0.128)"
}, R3 = {
  yellow1: "#fdfdf9",
  yellow2: "#fefce9",
  yellow3: "#fffab8",
  yellow4: "#fff394",
  yellow5: "#ffe770",
  yellow6: "#f3d768",
  yellow7: "#e4c767",
  yellow8: "#d5ae39",
  yellow9: "#ffe629",
  yellow10: "#ffdc00",
  yellow11: "#9e6c00",
  yellow12: "#473b1f"
}, U3 = {
  yellowA1: "#aaaa0006",
  yellowA2: "#f4dd0016",
  yellowA3: "#ffee0047",
  yellowA4: "#ffe3016b",
  yellowA5: "#ffd5008f",
  yellowA6: "#ebbc0097",
  yellowA7: "#d2a10098",
  yellowA8: "#c99700c6",
  yellowA9: "#ffe100d6",
  yellowA10: "#ffdc00",
  yellowA11: "#9e6c00",
  yellowA12: "#2e2000e0"
}, W3 = {
  yellow1: "color(display-p3 0.992 0.992 0.978)",
  yellow2: "color(display-p3 0.995 0.99 0.922)",
  yellow3: "color(display-p3 0.997 0.982 0.749)",
  yellow4: "color(display-p3 0.992 0.953 0.627)",
  yellow5: "color(display-p3 0.984 0.91 0.51)",
  yellow6: "color(display-p3 0.934 0.847 0.474)",
  yellow7: "color(display-p3 0.876 0.785 0.46)",
  yellow8: "color(display-p3 0.811 0.689 0.313)",
  yellow9: "color(display-p3 1 0.92 0.22)",
  yellow10: "color(display-p3 0.977 0.868 0.291)",
  yellow11: "color(display-p3 0.6 0.44 0)",
  yellow12: "color(display-p3 0.271 0.233 0.137)"
}, X3 = {
  yellowA1: "color(display-p3 0.675 0.675 0.024 / 0.024)",
  yellowA2: "color(display-p3 0.953 0.855 0.008 / 0.079)",
  yellowA3: "color(display-p3 0.988 0.925 0.004 / 0.251)",
  yellowA4: "color(display-p3 0.98 0.875 0.004 / 0.373)",
  yellowA5: "color(display-p3 0.969 0.816 0.004 / 0.491)",
  yellowA6: "color(display-p3 0.875 0.71 0 / 0.526)",
  yellowA7: "color(display-p3 0.769 0.604 0 / 0.542)",
  yellowA8: "color(display-p3 0.725 0.549 0 / 0.687)",
  yellowA9: "color(display-p3 1 0.898 0 / 0.781)",
  yellowA10: "color(display-p3 0.969 0.812 0 / 0.71)",
  yellowA11: "color(display-p3 0.6 0.44 0)",
  yellowA12: "color(display-p3 0.271 0.233 0.137)"
}, Y3 = {
  amber1: "#fefdfb",
  amber2: "#fefbe9",
  amber3: "#fff7c2",
  amber4: "#ffee9c",
  amber5: "#fbe577",
  amber6: "#f3d673",
  amber7: "#e9c162",
  amber8: "#e2a336",
  amber9: "#ffc53d",
  amber10: "#ffba18",
  amber11: "#ab6400",
  amber12: "#4f3422"
}, Z3 = {
  amberA1: "#c0800004",
  amberA2: "#f4d10016",
  amberA3: "#ffde003d",
  amberA4: "#ffd40063",
  amberA5: "#f8cf0088",
  amberA6: "#eab5008c",
  amberA7: "#dc9b009d",
  amberA8: "#da8a00c9",
  amberA9: "#ffb300c2",
  amberA10: "#ffb300e7",
  amberA11: "#ab6400",
  amberA12: "#341500dd"
}, G3 = {
  amber1: "color(display-p3 0.995 0.992 0.985)",
  amber2: "color(display-p3 0.994 0.986 0.921)",
  amber3: "color(display-p3 0.994 0.969 0.782)",
  amber4: "color(display-p3 0.989 0.937 0.65)",
  amber5: "color(display-p3 0.97 0.902 0.527)",
  amber6: "color(display-p3 0.936 0.844 0.506)",
  amber7: "color(display-p3 0.89 0.762 0.443)",
  amber8: "color(display-p3 0.85 0.65 0.3)",
  amber9: "color(display-p3 1 0.77 0.26)",
  amber10: "color(display-p3 0.959 0.741 0.274)",
  amber11: "color(display-p3 0.64 0.4 0)",
  amber12: "color(display-p3 0.294 0.208 0.145)"
}, o1 = {
  amberA1: "color(display-p3 0.757 0.514 0.024 / 0.016)",
  amberA2: "color(display-p3 0.902 0.804 0.008 / 0.079)",
  amberA3: "color(display-p3 0.965 0.859 0.004 / 0.22)",
  amberA4: "color(display-p3 0.969 0.82 0.004 / 0.35)",
  amberA5: "color(display-p3 0.933 0.796 0.004 / 0.475)",
  amberA6: "color(display-p3 0.875 0.682 0.004 / 0.495)",
  amberA7: "color(display-p3 0.804 0.573 0 / 0.557)",
  amberA8: "color(display-p3 0.788 0.502 0 / 0.699)",
  amberA9: "color(display-p3 1 0.686 0 / 0.742)",
  amberA10: "color(display-p3 0.945 0.643 0 / 0.726)",
  amberA11: "color(display-p3 0.64 0.4 0)",
  amberA12: "color(display-p3 0.294 0.208 0.145)"
}, l1 = {
  orange1: "#fefcfb",
  orange2: "#fff7ed",
  orange3: "#ffefd6",
  orange4: "#ffdfb5",
  orange5: "#ffd19a",
  orange6: "#ffc182",
  orange7: "#f5ae73",
  orange8: "#ec9455",
  orange9: "#f76b15",
  orange10: "#ef5f00",
  orange11: "#cc4e00",
  orange12: "#582d1d"
}, a1 = {
  orangeA1: "#c0400004",
  orangeA2: "#ff8e0012",
  orangeA3: "#ff9c0029",
  orangeA4: "#ff91014a",
  orangeA5: "#ff8b0065",
  orangeA6: "#ff81007d",
  orangeA7: "#ed6c008c",
  orangeA8: "#e35f00aa",
  orangeA9: "#f65e00ea",
  orangeA10: "#ef5f00",
  orangeA11: "#cc4e00",
  orangeA12: "#431200e2"
}, p1 = {
  orange1: "color(display-p3 0.995 0.988 0.985)",
  orange2: "color(display-p3 0.994 0.968 0.934)",
  orange3: "color(display-p3 0.989 0.938 0.85)",
  orange4: "color(display-p3 1 0.874 0.687)",
  orange5: "color(display-p3 1 0.821 0.583)",
  orange6: "color(display-p3 0.975 0.767 0.545)",
  orange7: "color(display-p3 0.919 0.693 0.486)",
  orange8: "color(display-p3 0.877 0.597 0.379)",
  orange9: "color(display-p3 0.9 0.45 0.2)",
  orange10: "color(display-p3 0.87 0.409 0.164)",
  orange11: "color(display-p3 0.76 0.34 0)",
  orange12: "color(display-p3 0.323 0.185 0.127)"
}, r1 = {
  orangeA1: "color(display-p3 0.757 0.267 0.024 / 0.016)",
  orangeA2: "color(display-p3 0.886 0.533 0.008 / 0.067)",
  orangeA3: "color(display-p3 0.922 0.584 0.008 / 0.15)",
  orangeA4: "color(display-p3 1 0.604 0.004 / 0.314)",
  orangeA5: "color(display-p3 1 0.569 0.004 / 0.416)",
  orangeA6: "color(display-p3 0.949 0.494 0.004 / 0.455)",
  orangeA7: "color(display-p3 0.839 0.408 0 / 0.514)",
  orangeA8: "color(display-p3 0.804 0.349 0 / 0.62)",
  orangeA9: "color(display-p3 0.878 0.314 0 / 0.8)",
  orangeA10: "color(display-p3 0.843 0.29 0 / 0.836)",
  orangeA11: "color(display-p3 0.76 0.34 0)",
  orangeA12: "color(display-p3 0.323 0.185 0.127)"
}, d1 = {
  blackA1: "rgba(0, 0, 0, 0.05)",
  blackA2: "rgba(0, 0, 0, 0.1)",
  blackA3: "rgba(0, 0, 0, 0.15)",
  blackA4: "rgba(0, 0, 0, 0.2)",
  blackA5: "rgba(0, 0, 0, 0.3)",
  blackA6: "rgba(0, 0, 0, 0.4)",
  blackA7: "rgba(0, 0, 0, 0.5)",
  blackA8: "rgba(0, 0, 0, 0.6)",
  blackA9: "rgba(0, 0, 0, 0.7)",
  blackA10: "rgba(0, 0, 0, 0.8)",
  blackA11: "rgba(0, 0, 0, 0.9)",
  blackA12: "rgba(0, 0, 0, 0.95)"
}, e1 = {
  blackA1: "color(display-p3 0 0 0 / 0.05)",
  blackA2: "color(display-p3 0 0 0 / 0.1)",
  blackA3: "color(display-p3 0 0 0 / 0.15)",
  blackA4: "color(display-p3 0 0 0 / 0.2)",
  blackA5: "color(display-p3 0 0 0 / 0.3)",
  blackA6: "color(display-p3 0 0 0 / 0.4)",
  blackA7: "color(display-p3 0 0 0 / 0.5)",
  blackA8: "color(display-p3 0 0 0 / 0.6)",
  blackA9: "color(display-p3 0 0 0 / 0.7)",
  blackA10: "color(display-p3 0 0 0 / 0.8)",
  blackA11: "color(display-p3 0 0 0 / 0.9)",
  blackA12: "color(display-p3 0 0 0 / 0.95)"
}, s1 = {
  whiteA1: "rgba(255, 255, 255, 0.05)",
  whiteA2: "rgba(255, 255, 255, 0.1)",
  whiteA3: "rgba(255, 255, 255, 0.15)",
  whiteA4: "rgba(255, 255, 255, 0.2)",
  whiteA5: "rgba(255, 255, 255, 0.3)",
  whiteA6: "rgba(255, 255, 255, 0.4)",
  whiteA7: "rgba(255, 255, 255, 0.5)",
  whiteA8: "rgba(255, 255, 255, 0.6)",
  whiteA9: "rgba(255, 255, 255, 0.7)",
  whiteA10: "rgba(255, 255, 255, 0.8)",
  whiteA11: "rgba(255, 255, 255, 0.9)",
  whiteA12: "rgba(255, 255, 255, 0.95)"
}, i1 = {
  whiteA1: "color(display-p3 1 1 1 / 0.05)",
  whiteA2: "color(display-p3 1 1 1 / 0.1)",
  whiteA3: "color(display-p3 1 1 1 / 0.15)",
  whiteA4: "color(display-p3 1 1 1 / 0.2)",
  whiteA5: "color(display-p3 1 1 1 / 0.3)",
  whiteA6: "color(display-p3 1 1 1 / 0.4)",
  whiteA7: "color(display-p3 1 1 1 / 0.5)",
  whiteA8: "color(display-p3 1 1 1 / 0.6)",
  whiteA9: "color(display-p3 1 1 1 / 0.7)",
  whiteA10: "color(display-p3 1 1 1 / 0.8)",
  whiteA11: "color(display-p3 1 1 1 / 0.9)",
  whiteA12: "color(display-p3 1 1 1 / 0.95)"
}, c1 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  amber: Y3,
  amberA: Z3,
  amberDark: Fo,
  amberDarkA: Io,
  amberDarkP3: Eo,
  amberDarkP3A: Lo,
  amberP3: G3,
  amberP3A: o1,
  blackA: d1,
  blackP3A: e1,
  blue: Gl,
  blueA: o3,
  blueDark: I0,
  blueDarkA: E0,
  blueDarkP3: L0,
  blueDarkP3A: q0,
  blueP3: l3,
  blueP3A: a3,
  bronze: h3,
  bronzeA: C3,
  bronzeDark: Ao,
  bronzeDarkA: fo,
  bronzeDarkP3: no,
  bronzeDarkP3A: bo,
  bronzeP3: _3,
  bronzeP3A: N3,
  brown: D3,
  brownA: j3,
  brownDark: so,
  brownDarkA: io,
  brownDarkP3: co,
  brownDarkP3A: yo,
  brownP3: z3,
  brownP3A: $3,
  crimson: zl,
  crimsonA: $l,
  crimsonDark: i0,
  crimsonDarkA: c0,
  crimsonDarkP3: y0,
  crimsonDarkP3A: A0,
  crimsonP3: hl,
  crimsonP3A: Cl,
  cyan: p3,
  cyanA: r3,
  cyanDark: T0,
  cyanDarkA: V0,
  cyanDarkP3: B0,
  cyanDarkP3A: H0,
  cyanP3: d3,
  cyanP3A: e3,
  gold: O3,
  goldA: x3,
  goldDark: to,
  goldDarkA: go,
  goldDarkP3: mo,
  goldDarkP3A: uo,
  goldP3: S3,
  goldP3A: M3,
  grass: k3,
  grassA: v3,
  grassDark: ao,
  grassDarkA: po,
  grassDarkP3: ro,
  grassDarkP3A: eo,
  grassP3: w3,
  grassP3A: P3,
  gray: Ho,
  grayA: Jo,
  grayDark: $,
  grayDarkA: h,
  grayDarkP3: C,
  grayDarkP3A: _,
  grayP3: Ko,
  grayP3A: Qo,
  green: t3,
  greenA: g3,
  greenDark: Z0,
  greenDarkA: G0,
  greenDarkP3: oo,
  greenDarkP3A: lo,
  greenP3: m3,
  greenP3A: u3,
  indigo: Wl,
  indigoA: Xl,
  indigoDark: x0,
  indigoDarkA: S0,
  indigoDarkP3: M0,
  indigoDarkP3A: F0,
  indigoP3: Yl,
  indigoP3A: Zl,
  iris: Kl,
  irisA: Ql,
  irisDark: C0,
  irisDarkA: _0,
  irisDarkP3: N0,
  irisDarkP3A: O0,
  irisP3: Rl,
  irisP3A: Ul,
  jade: A3,
  jadeA: f3,
  jadeDark: U0,
  jadeDarkA: W0,
  jadeDarkP3: X0,
  jadeDarkP3A: Y0,
  jadeP3: n3,
  jadeP3A: b3,
  lime: H3,
  limeA: J3,
  limeDark: ho,
  limeDarkA: Co,
  limeDarkP3: _o,
  limeDarkP3A: No,
  limeP3: K3,
  limeP3A: Q3,
  mauve: Ro,
  mauveA: Uo,
  mauveDark: N,
  mauveDarkA: O,
  mauveDarkP3: x,
  mauveDarkP3A: S,
  mauveP3: Wo,
  mauveP3A: Xo,
  mint: q3,
  mintA: T3,
  mintDark: Do,
  mintDarkA: jo,
  mintDarkP3: zo,
  mintDarkP3A: $o,
  mintP3: V3,
  mintP3A: B3,
  olive: dl,
  oliveA: el,
  oliveDark: B,
  oliveDarkA: H,
  oliveDarkP3: J,
  oliveDarkP3A: K,
  oliveP3: sl,
  oliveP3A: il,
  orange: l1,
  orangeA: a1,
  orangeDark: qo,
  orangeDarkA: To,
  orangeDarkP3: Vo,
  orangeDarkP3A: Bo,
  orangeP3: p1,
  orangeP3A: r1,
  pink: _l,
  pinkA: Nl,
  pinkDark: f0,
  pinkDarkA: n0,
  pinkDarkP3: b0,
  pinkDarkP3A: t0,
  pinkP3: Ol,
  pinkP3A: xl,
  plum: Sl,
  plumA: Ml,
  plumDark: g0,
  plumDarkA: m0,
  plumDarkP3: u0,
  plumDarkP3A: k0,
  plumP3: Fl,
  plumP3A: Il,
  purple: El,
  purpleA: Ll,
  purpleDark: v0,
  purpleDarkA: w0,
  purpleDarkP3: P0,
  purpleDarkP3A: D0,
  purpleP3: ql,
  purpleP3A: Tl,
  red: ml,
  redA: ul,
  redDark: o0,
  redDarkA: l0,
  redDarkP3: a0,
  redDarkP3A: p0,
  redP3: kl,
  redP3A: vl,
  ruby: wl,
  rubyA: Pl,
  rubyDark: r0,
  rubyDarkA: d0,
  rubyDarkP3: e0,
  rubyDarkP3A: s0,
  rubyP3: Dl,
  rubyP3A: jl,
  sage: ll,
  sageA: al,
  sageDark: L,
  sageDarkA: q,
  sageDarkP3: T,
  sageDarkP3A: V,
  sageP3: pl,
  sageP3A: rl,
  sand: cl,
  sandA: yl,
  sandDark: Q,
  sandDarkA: R,
  sandDarkP3: U,
  sandDarkP3A: W,
  sandP3: Al,
  sandP3A: fl,
  sky: F3,
  skyA: I3,
  skyDark: ko,
  skyDarkA: vo,
  skyDarkP3: wo,
  skyDarkP3A: Po,
  skyP3: E3,
  skyP3A: L3,
  slate: Yo,
  slateA: Zo,
  slateDark: M,
  slateDarkA: F,
  slateDarkP3: I,
  slateDarkP3A: E,
  slateP3: Go,
  slateP3A: ol,
  teal: s3,
  tealA: i3,
  tealDark: J0,
  tealDarkA: K0,
  tealDarkP3: Q0,
  tealDarkP3A: R0,
  tealP3: c3,
  tealP3A: y3,
  tomato: nl,
  tomatoA: bl,
  tomatoDark: X,
  tomatoDarkA: Y,
  tomatoDarkP3: Z,
  tomatoDarkP3A: G,
  tomatoP3: tl,
  tomatoP3A: gl,
  violet: Vl,
  violetA: Bl,
  violetDark: j0,
  violetDarkA: z0,
  violetDarkP3: $0,
  violetDarkP3A: h0,
  violetP3: Hl,
  violetP3A: Jl,
  whiteA: s1,
  whiteP3A: i1,
  yellow: R3,
  yellowA: U3,
  yellowDark: Oo,
  yellowDarkA: xo,
  yellowDarkP3: So,
  yellowDarkP3A: Mo,
  yellowP3: W3,
  yellowP3A: X3
}, Symbol.toStringTag, { value: "Module" })), y1 = {
  mauve: "mauvedark",
  tomato: "mauvedark",
  red: "mauvedark",
  ruby: "mauvedark",
  crimson: "mauvedark",
  pink: "mauvedark",
  plum: "mauvedark",
  purple: "mauvedark",
  violet: "mauvedark",
  slate: "slatedark",
  iris: "slatedark",
  indigo: "slatedark",
  blue: "slatedark",
  sky: "slate",
  cyan: "slatedark",
  sage: "sagedark",
  mint: "sage",
  teal: "sagedark",
  jade: "sagedark",
  green: "sagedark",
  olive: "olivedark",
  grass: "olivedark",
  lime: "olive",
  sand: "sanddark",
  yellow: "sand",
  amber: "sand",
  orange: "sanddark",
  brown: "sanddark",
  gold: "sanddark",
  // Not officially specified.
  bronze: "sanddark",
  // Not officially specified.
  gray: "graydark"
  // Not officially specified.
};
function y(l) {
  var s;
  const p = (s = l.match(
    /^(?<base>.+?)(?<dark>dark)?(?<p3>p3)?(?<alpha>a)?$/i
  )) == null ? void 0 : s.groups;
  if (!(p != null && p.base))
    throw new Error(`Invalid color name: ${l}`);
  const { base: r, dark: e, p3: a, alpha: o } = p;
  return {
    base: r.toLowerCase(),
    dark: e !== void 0,
    p3: a !== void 0,
    alpha: o !== void 0
  };
}
function c(l) {
  const { base: p, dark: r, p3: e, alpha: a } = l;
  let o = p;
  return r && (o += "dark"), e && (o += "p3"), a && (o += "a"), o;
}
function A1(l = {}) {
  return {
    theme: {
      colors: {
        transparent: "transparent",
        current: "currentColor",
        black: "black",
        white: "white",
        ...f1(c1, l)
      }
    }
  };
}
function f1(l, p) {
  var s;
  const { include: r = void 0, exclude: e = [] } = p, a = n1(r, e), o = {};
  for (const [d, A] of Object.entries(l)) {
    if (!a(d))
      continue;
    const f = d.toLowerCase(), n = {};
    for (const [w, P] of Object.entries(A)) {
      const m = (s = w.match(/\d+$/)) == null ? void 0 : s[0];
      m && (n[m] = P);
    }
    o[f] = n;
  }
  return o;
}
function n1(l, p) {
  const r = l == null ? void 0 : l.map(y), e = p.map(y);
  return (a) => {
    const o = y(a);
    return !(r && !r.some((s) => u(s, o)) || e.some((s) => u(s, o)));
  };
}
function u(l, p) {
  return l.base === p.base && l.p3 === p.p3 && l.alpha === p.alpha;
}
function b1(l = {}) {
  const { disableSemantics: p } = l;
  return p ? () => {
  } : t1;
}
function t1({ addComponents: l, config: p, theme: r }) {
  const e = r("colors"), a = p("prefix");
  for (const [o, s] of Object.entries(e)) {
    if (y(o).dark || !g(s))
      continue;
    const { darkColorName: d, foregroundColorName: A } = g1(o), b = e[d];
    if (!b || !g(b))
      continue;
    const f = e[A], n = f && g(f);
    l({
      [`.bg-${o}-app`]: i(
        `bg-${o}-1`,
        `${a}dark:bg-${d}-1`
      ),
      [`.bg-${o}-subtle`]: i(
        `bg-${o}-2`,
        `${a}dark:bg-${d}-2`
      ),
      [`.bg-${o}-ui`]: i(
        `bg-${o}-3`,
        `hover:bg-${o}-4`,
        `active:bg-${o}-5`,
        `${a}dark:bg-${d}-3`,
        `${a}dark:hover:bg-${d}-4`,
        `${a}dark:active:bg-${d}-5`
      ),
      [`.bg-${o}-ghost`]: i(
        "bg-transparent",
        `hover:bg-${o}-4`,
        `active:bg-${o}-5`,
        `${a}dark:bg-transparent`,
        `${a}dark:hover:bg-${d}-4`,
        `${a}dark:active:bg-${d}-5`
      ),
      [`.bg-${o}-action`]: i(
        `bg-${o}-4`,
        `hover:bg-${o}-5`,
        `active:bg-${o}-6`,
        `${a}dark:bg-${d}-4`,
        `${a}dark:hover:bg-${d}-5`,
        `${a}dark:active:bg-${d}-6`
      ),
      [`.bg-${o}-solid`]: i(
        `bg-${o}-9`,
        `hover:bg-${o}-10`,
        `${a}dark:bg-${d}-9`,
        `${a}dark:hover:bg-${d}-10`,
        n ? `text-${A}-12` : ""
      ),
      [`.border-${o}-dim`]: i(
        `border-${o}-6`,
        `${a}dark:border-${d}-6`
      ),
      [`.border-${o}-normal`]: i(
        `border-${o}-7`,
        `hover:border-${o}-8`,
        `${a}dark:border-${d}-7`,
        `${a}dark:hover:border-${d}-8`
      ),
      [`.divide-${o}-dim`]: i(
        `divide-${o}-6`,
        `${a}dark:divide-${d}-6`
      ),
      [`.divide-${o}-normal`]: i(
        `divide-${o}-7`,
        `hover:divide-${o}-8`,
        `${a}dark:divide-${d}-7`,
        `${a}dark:hover:divide-${d}-8`
      ),
      [`.text-${o}-dim`]: i(
        `text-${o}-11`,
        `${a}dark:text-${d}-11`
      ),
      [`.text-${o}-normal`]: i(
        `text-${o}-12`,
        `${a}dark:text-${d}-12`
      )
    });
  }
}
function g(l) {
  if (typeof l == "string")
    return !1;
  for (let p = 1; p <= 12; p++)
    if (!l[p])
      return !1;
  return !0;
}
function g1(l) {
  const { base: p, p3: r, alpha: e } = y(l);
  return p === "black" ? {
    darkColorName: c({
      base: "white",
      dark: !1,
      p3: r,
      alpha: e
    }),
    foregroundColorName: c({
      base: "white",
      dark: !1,
      p3: r,
      alpha: !1
    })
  } : p === "white" ? {
    darkColorName: c({
      base: "black",
      dark: !1,
      p3: r,
      alpha: e
    }),
    foregroundColorName: c({
      base: "black",
      dark: !1,
      p3: r,
      alpha: !1
    })
  } : {
    darkColorName: c({
      base: p,
      dark: !0,
      p3: r,
      alpha: e
    }),
    foregroundColorName: c({
      base: y1[p],
      dark: !1,
      p3: r,
      alpha: !1
    })
  };
}
function i(...l) {
  return { [`@apply ${l.filter((r) => r !== "").map((r) => r.replaceAll(" ", "_")).join(" ")}`]: {} };
}
const m1 = z.withOptions(b1, A1);
export {
  m1 as default
};
